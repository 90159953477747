import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import EmailInputElement from "../../../../components/input/v1/EmailInputElement"
import PasswordInputElement from "../../../../components/input/v1/PasswordInputElement"
import PhoneInputElement from "../../../../components/input/v1/PhoneInputElement"
import TextInputElement from "../../../../components/input/v1/TextInputElement"
import { EMAIL_REGEX, PASSWORD_REGEX } from "../../../../utils/Constants"
import { PasswordInputIcon } from "../../../../utils/enums/PasswordInputIcon"
import { PasswordInputType } from "../../../../utils/enums/PasswordInputType"
import { validateEmail, validatePassword, validatePasswordConfirm } from "../../../../utils/Functions"

interface Props {
    onSubmit: (inputData: { [key: string]: string }, newsletterAccepted: boolean,) => void;
    emailError: boolean,
}

const RegistrationBox: React.FC<Props> = ({
    onSubmit,
    emailError,
}) => {
    const navigate = useNavigate()

    const [emailRegistered, setEmailRegistered] = useState(emailError)

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        name: '',
        familyName: '',
        phoneNumber: '',
        email: '',
        password: '',
        passwordConfirm: '',
    });
    const [errors, setErrors] = useState<{ [key: string]: string }>({
        email: '',
        password: '',
        passwordConfirm: '',
    });

    const [newsletterAccepted, setNewsletterAccepted] = useState(false)

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        const processedValue = name === "email" ? value.toLowerCase() : value;

        setInputs((prev) => ({
            ...prev,
            [name]: processedValue,
        }));

        validateField(name, processedValue);
    };

    const validateField = (name: string, value: string) => {
        let error = '';
        switch (name) {
            case "email":
                error = validateEmail(value);
                break;
            case "password":
                error = validatePassword(value);
                break;
            case "passwordConfirm":
                error = validatePasswordConfirm(inputs.password, value);
                break;
            default:
                break;
        }

        setErrors((prev) => ({
            ...prev,
            [name]: error,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs, newsletterAccepted);
    }

    const toggleNewsletterAcceptance = () => {
        setNewsletterAccepted((prev) => !prev);
    };


    useEffect(() => {
        setEmailRegistered(emailError)
    }, [emailError])

    const isSubmitButtonEnabled = () => {
        const isEmailValid = EMAIL_REGEX.test(inputs.email);
        const isPasswordValid = PASSWORD_REGEX.test(inputs.password);
        const areAllFieldsFilled = Object.values(inputs).every((input) => input !== "");
        const isPasswordConfirmed = inputs.passwordConfirm === inputs.password;
        return (
            isEmailValid && isPasswordValid && areAllFieldsFilled && isPasswordConfirmed
        );
    };

    // ********************************************************************************
    return (
        <div className="w-full flex flex-col items-center pb-[10px] space-y-[20px]">
            <Header />
            <FormSection
                inputs={inputs}
                errors={errors}
                emailRegistered={emailRegistered}
                newsletterAccepted={newsletterAccepted}
                handleInputChange={handleInputChange}
                toggleNewsletterAcceptance={toggleNewsletterAcceptance}
                handleSubmit={handleSubmit}
                onBackToLoginClick={() => navigate("/login")}
                isSubmitButtonEnabled={isSubmitButtonEnabled()}
            />
        </div>
    )
}

export default RegistrationBox

const Header = () => (
    <>
        <div className="font-bold text-[30px] leading-[40px]">
            Crea il tuo account
        </div>
        <div className="w-full font-normal text-[14px] text-center">
            Creando un account, dichiari di aver letto e compreso le
            <a
                href={"/assets/personal-data-treatment.pdf"}
                target="_blank"
                rel="noopener noreferrer"
                title="Informazioni relative al trattamento dei dati personali"
                className="underline text-black font-semibold ml-[5px] cursor-pointer"
            >
                Informazioni relative al trattamento dei dati personali
            </a>.
        </div>
    </>
)

const FormSection: React.FC<{
    inputs: { [key: string]: string };
    errors: { [key: string]: string };
    emailRegistered: boolean;
    newsletterAccepted: boolean;
    handleInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    toggleNewsletterAcceptance: () => void;
    handleSubmit: (e: React.FormEvent) => void;
    onBackToLoginClick: () => void,
    isSubmitButtonEnabled: boolean,
}> = ({
    inputs,
    errors,
    emailRegistered,
    newsletterAccepted,
    handleInputChange,
    toggleNewsletterAcceptance,
    handleSubmit,
    onBackToLoginClick,
    isSubmitButtonEnabled,
}) => (
        <form
            className="w-full  space-y-[20px]"
            onSubmit={handleSubmit}
        >
            <TextInputElement
                name="name"
                label="Nome"
                placeholder="Inserisci nome"
                errorText={""}
                inputValue={inputs.name}
                onChange={handleInputChange}
            />
            <TextInputElement
                name="familyName"
                label="Cognome"
                placeholder="Inserisci cognome"
                errorText={""}
                inputValue={inputs.familyName}
                onChange={handleInputChange}
            />
            <PhoneInputElement
                name="phoneNumber"
                label="Numero di telefono"
                placeholder="Inserisci numero di telefono"
                errorText={""}
                inputValue={inputs.phoneNumber}
                onInputChange={handleInputChange}
            />
            <EmailInputElement
                name="email"
                label="Indirizzo e-mail"
                placeholder="nome@esempio.com"
                inputValue={inputs.email}
                errorText={errors.email}
                onChange={handleInputChange}
            />
            {
                emailRegistered &&
                <div className="font-semibold text-[12px] leading-[17px] mb-[10px] text-holifya-error">L'indirizzo e-mail è già in uso. <span onClick={onBackToLoginClick} className="underline_text pointer error_text">
                    Clicca qui per accedere.</span>
                </div>
            }
            <div className="w-full font-semibold text-[14px] leading-[22px] text-holifya-blue">
                Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
            </div>
            <PasswordInputElement
                name="password"
                label="Password"
                placeholder="Password"
                inputValue={inputs.password}
                errorText={errors.password}
                disabled={inputs.email === "" || errors.email !== ""}
                image={PasswordInputIcon.ShowDisabled}
                type={PasswordInputType.Password}
                toggleTypeEnabled={errors.email === "" && inputs.password !== ""}
                onIconClick={() => console.log()}
                onChange={handleInputChange}
            />
            <PasswordInputElement
                name="passwordConfirm"
                label="Conferma Password"
                placeholder="Conferma password"
                inputValue={inputs.passwordConfirm}
                errorText={errors.passwordConfirm}
                disabled={inputs.password === "" || (errors.email !== "" || errors.password !== "")}
                image={PasswordInputIcon.ShowDisabled}
                type={PasswordInputType.Password}
                toggleTypeEnabled={errors.email === "" && errors.password === "" && inputs.passwordConfirm !== ""}
                onIconClick={() => console.log()}
                onChange={handleInputChange}
            />
            <Agreements
                toggleNewsletterAcceptance={toggleNewsletterAcceptance}
                newsletterAccepted={newsletterAccepted}
            />
            <SubmitButton isSubmitButtonEnabled={isSubmitButtonEnabled} />
        </form>
    )

const Agreements: React.FC<{
    toggleNewsletterAcceptance: () => void,
    newsletterAccepted: boolean,
}> = ({
    toggleNewsletterAcceptance,
    newsletterAccepted,
}) => (
        <div className="w-full flex justify-between text-[14px] py-[10px]">
            <div
                className="w-[26px] h-[26px] rounded-[8px] border border-solid border-black ml-[20px] cursor-pointer flex items-center justify-center"
                onClick={toggleNewsletterAcceptance}
            >
                {newsletterAccepted && <div className="w-[20px] h-[20px] rounded-[6px] bg-black" />}
            </div>
            <div className="w-[calc(100%-60px)] h-full">Dichiaro di voler ricevere newsletter o comunicazioni commerciali da parte di Holifya.<br></br>
                <a
                    href={"/assets/newsletter.pdf"}
                    target="_blank"
                    rel="noopener noreferrer"
                    title="Informativa relativa alla newsletter"
                    className="underline text-black font-semibold cursor-pointer"
                >
                    Informativa relativa alla newsletter
                </a>
                .</div>
        </div>
    )

const SubmitButton: React.FC<{
    isSubmitButtonEnabled: boolean,
}> = ({
    isSubmitButtonEnabled,
}) => (
        <div className="w-full h-[55px] my-[20px]">
            <PrimaryButton
                text="Continua"
                pointer={isSubmitButtonEnabled}
                disabled={!isSubmitButtonEnabled}
                fontSize={12}
            />
        </div>
    )
