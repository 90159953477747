import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../utils/api-objects/Exam"
import { getAllExamsByType } from "../../../utils/apis/emr-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../utils/enums/BucketFolder"
import { EventType } from "../../../utils/enums/EventType"
import BackButton from "../components/BackButton"
import LoadingSpinner from "../components/Loaders"
import { PdfCard, PdfType } from "../components/PdfCard"

const ResultsAndProgresses = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email

    const navigate = useNavigate()

    const [report, setReport] = useState<Exam>()
    const [isLoading, setIsLoading] = useState(true)

    useEffect(() => {
        if (!userId || !idToken) {
            return
        }
        getAllExamsByType(
            userId,
            idToken,
            BucketFolder.CrossResults,
            (exams) => {
                setReport(exams[0])
                setIsLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLoading(false)
            }
        );
        if (!email) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "results_and_progresses_page",
        )
    }, []);

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Diagnostica"
                    onClick={() => navigate("/diagnostica")}
                />
                <div className="font-bold text-[25px] leading-[33px]">
                    Risultati e progressi
                </div>
            </div>
            <div className="font-normal text-[16px] leading-[26px]">
                In questa cartella trovi il risultato aggregato del test del DNA e delle analisi degli Aminoacidi.
            </div>
            {
                !isLoading &&
                (report && <PdfCard exam={report} pdfType={PdfType.CrossResults} />)
            }
            <div className="p-[10px]" />
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default ResultsAndProgresses
