import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { saveEvent } from "../../../../utils/apis/user-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { EventType } from "../../../../utils/enums/EventType";
import { QuestionCategory } from "../../../../utils/enums/Surveys";
import { fromQuestionCategoryToString } from "../../../../utils/Functions";
import BackButton from "../../components/BackButton";

const HealthAssessment = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()

    useEffect(() => {
        if (!idToken || !email) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_assessment_page",
        )
    })

    const categories = [
        QuestionCategory.PersonalGoals,
        QuestionCategory.FamilyHistoryDiseases,
        QuestionCategory.LifestyleSports,
        QuestionCategory.DietaryHabits,
        QuestionCategory.Notes,
    ];

    const onFolderClick = (category: QuestionCategory) => {
        navigate(`category/${category}`)
        if (!email || !idToken) return
        saveEvent(
            email,
            `${category}_folder_clicked`,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_assessment_page",
        )
    }

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Test e referti"
                    onClick={() => navigate("/diagnostica/tests-and-reports")}
                />
                <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                    Health Assessment
                </div>
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {categories.map((category) => (
                    <HAFolder
                        key={category}
                        category={category}
                        onClick={() => onFolderClick(category)}
                    />
                ))}
            </div>
            <div className="p-[10px]" />
        </div>
    )
}

export default HealthAssessment

interface HAFolderProps {
    category: QuestionCategory,
    onClick: () => void,
}

const HAFolder: React.FC<HAFolderProps> = ({
    category,
    onClick,
}) => {
    return (
        <div className="w-[calc(100%-4px)] h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer" onClick={onClick}>
            <div className="w-[90%]">
                <div className="font-bold text-[20px] leading-[33px]">{fromQuestionCategoryToString(category)}</div>
            </div>
        </div>
    )
}
