import { useEffect } from "react"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../components/buttons/WhiteButton"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { ProductType } from "../../../utils/enums/ProductType"
import { handleShopifyProductName } from "../../../utils/Functions"

const PurchasePage = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => console.log(success),
            (error) => { console.log(error) },
            "purchase_page",
        )
    }, [])

    const products = [
        ProductType.DnaTest,
        ProductType.SubHealth,
        ProductType.SubNutrition,
        ProductType.ExpertConsult,
        ProductType.Nutritionist,
    ];

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col">
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                Acquista
            </div>
            <div className="font-semibold text-[12px] leading-[20px] mb-[20px]">
                Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="text-holifya-blue">{email}</span> al momento dell'acquisto.
            </div>
            {products.map((product) => (
                <PurchaseCard key={product} product={product} />
            ))}
            <div className="p-[5px]" />
        </div>
    )
}

export default PurchasePage

const PurchaseCard: React.FC<{
    product: ProductType,
}> = ({
    product,
}) => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData} = useUserData()
    const email = userData.email
    const PRODUCT_DETAILS = {
        [ProductType.DnaTest]: {
            title: "DNA Test",
            url: "https://www.holifya.com/products/dna-complete",
        },
        [ProductType.SubHealth]: {
            title: "Abbonamento",
            url: "https://www.holifya.com/products/percorso-health",
        },
        [ProductType.SubNutrition]: {
            title: "Abbonamento",
            url: "https://www.holifya.com/products/percorso-nutrition",
        },
        [ProductType.ExpertConsult]: {
            title: "Consulto",
            url: "https://www.holifya.com/pages/consulto-nutrigenetico",
        },
        [ProductType.Nutritionist]: {
            title: "Consulto",
            url: "https://www.holifya.com/pages/consulto",
        },
        [ProductType.BloodTest]: {
            title: "",
            url: "",
        },
        [ProductType.SubHealthDna]: {
            title: "",
            url: "",
        },
        [ProductType.SubNutritionDna]: {
            title: "",
            url: "",
        },
        [ProductType.SubHealthUpsell]: {
            title: "",
            url: "",
        },
    };

    const productDetails = PRODUCT_DETAILS[product];

    if (!productDetails) {
        console.error(`No details found for product: ${product}`);
        return null;
    }

    const { title, url } = productDetails;

    const onPrimaryButtonClick = () => {
        if(!email || !idToken) return
        window.open(url, "_blank")
        saveEvent(
            email,
            `purchase_button_on_product_${product}`.replace("-", "_"),
            (success) => {
                console.log(success)
            },
            (error) => {
                console.log(error)
            },
            "purchase_page"
        )
    }

    const onInfoButtonClick = () => {
        if(!email || !idToken) return
        window.open(url, "_blank")
        saveEvent(
            email,
            `info_button_on_product_${product}`.replace("-", "_"),
            (success) => {
                console.log(success)
            },
            (error) => {
                console.log(error)
            },
            "purchase_page"
        )
    }

    // ********************************************************************************

    return (
        <div className="w-full rounded-[15px] shadow-platform p-[10px] mb-[20px] md:flex md:justify-between">
            <div className="md:flex md:flex-col md:justify-center">
                <div className="font-bold text-[12px] leading-[20px]">{title}</div>
                <div className="font-bold text-[25px] leading-[25px] mt-[10px] mb-[30px]">{handleShopifyProductName(product)}</div>
            </div>
            <div className="w-full h-[100px] flex flex-col justify-between md:w-[330px]">
                <div className="w-full h-[50px]">
                    <PrimaryButton
                        text={"Acquista"}
                        pointer={true}
                        disabled={false}
                        onClick={onPrimaryButtonClick}
                    />
                </div>
                <div className="w-full h-[40px]">
                    <WhiteButton
                        text={"Più informazioni"}
                        onClick={onInfoButtonClick}
                        fontSize={16}
                        uppercase={false}
                    />
                </div>
            </div>
        </div>
    )
}
