import { CalendlyAppointment } from "../api-objects/CalendlyAppointment";
import { Consult, ConsultsLeft } from "../api-objects/Consults";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { ConsultType, PurchaseType } from "../enums/Consults";
import { ErrorWithMessageCallback, GetConsultsCallback, ReduceConsultsLeftCallback, SuccessWithMessageCallback } from "./callback-helpers";

export async function addConsult(
    user_id: string,
    id_token: string,
    consult_type: ConsultType,
    consults_left: number,
    purchase_type: PurchaseType,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
    sub_months?: number,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/consults/${user_id}`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    consult_type,
                    consults_left,
                    purchase_type,
                    ...(sub_months !== undefined && { sub_months }),  // Include only if defined
                }
            ),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create consult failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function readsConsultsLeft(
    user_id: string,
    id_token: string,
    consult_type: ConsultType,
    purchase_type?: PurchaseType,
): Promise<number> {
    const realType = purchase_type !== undefined ? purchase_type : PurchaseType.OneOff
    const endpoint = purchase_type !== undefined
        ? `${BASE_URL}/api/consults/consult_type/${consult_type}/user_id/${user_id}/purchase_type/${realType}`
        : `${BASE_URL}/api/consults/consult_type/${consult_type}/user_id/${user_id}`

    try {
        const response = await fetch(endpoint, requestOptionsGET(id_token))
        if (response.ok) {
            const consultsLeft: number = await response.json()
            return consultsLeft;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else {
            throw new Error("Errore");
        }
    } catch (error) {
        console.error("Get consults left failed:", error);
        throw error;
    }
}

export async function readsAllConsults(
    user_id: string,
    id_token: string,
    onSuccess: GetConsultsCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/consults/user_id/${user_id}`, requestOptionsGET(id_token))
        if (response.ok) {
            const consults: Consult[] = await response.json()
            onSuccess(consults)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Get all consults left failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function reducesConsultsLeft(
    user_id: string,
    id_token: string,
    consult_type: ConsultType,
    onSuccess: ReduceConsultsLeftCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/consults/reduce/${user_id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    consult_type,
                }
            ),
        })
        if (response.ok) {
            const consultsLeft: ConsultsLeft = await response.json()
            onSuccess(consultsLeft)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Reduce consults left failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function increaseConsultsLeft(
    user_id: string,
    id_token: string,
    consult_type: ConsultType,
    onSuccess: ReduceConsultsLeftCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/consults/increase/${user_id}`, {
            method: 'PUT',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify(
                {
                    consult_type,
                }
            ),
        })
        if (response.ok) {
            const consultsLeft: ConsultsLeft = await response.json()
            onSuccess(consultsLeft)
        } else {
            onError("Errore")
        }
    } catch (error) {
        console.error("Increase consults left failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getAppointments(
    idToken: string,
    email: string,
): Promise<CalendlyAppointment[]> {
    try {
        const response = await fetch(`${BASE_URL}/api/booking/appointments/${email}`, requestOptionsGET(idToken));
        if (response.ok) {
            const appointments: CalendlyAppointment[] = await response.json();
            return appointments;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else if (response.status === 404) {
            throw new Error(`Nessun appuntamento trovato per ${email}`);
        } else {
            throw new Error("Errore nel recuperare gli appuntamenti");
        }
    } catch (error) {
        if (error instanceof Error)
            if (error.message.includes("Session expired")) {
                throw new Error("Session expired");
            } else if (error.message.includes("Nessun appuntamento trovato")) {
                throw new Error(error.message);
            }
        throw new Error("An unexpected error occurred");
    }
}