import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import BackButton from "../components/BackButton"
import { Folder } from "../components/Folder"
import FolderBox from "../components/FolderBox"

const TestsAndReports = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()

    const folderConfig = [
        {
            folder: Folder.DNATest,
            eventType: EventType.DnaTestFolderClicked,
            route: "dna-test",
        },
        {
            folder: Folder.BloodExams,
            eventType: EventType.BloodTestFolderClicked,
            route: "blood-test",
        },
        {
            folder: Folder.HealthAssessment,
            eventType: EventType.HealthAssessmentFolderClicked,
            route: "/health-assessment",
        },
        {
            folder: Folder.UploadedForYou,
            eventType: EventType.UploadedForYouFolderClicked,
            route: "uploaded-for-you",
        },
        {
            folder: Folder.UploadedFromYou,
            eventType: EventType.UploadedFromYouFolderClicked,
            route: "uploaded-from-you",
        },
    ];

    const handleFolderClick = (route: string, eventType: EventType) => {
        navigate(route);
        if (!email) return;
        saveEvent(
            email,
            eventType,
            (success) => console.log(success),
            (error) => console.log(error),
            "tests_and_reports_page"
        );
    };

    useEffect(() => {
        if (!email) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "tests_and_reports_page",
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Diagnostica"
                    onClick={() => navigate("/diagnostica")}
                />
                <div className="font-bold text-[25px] leading-[33px]">
                    Test e referti
                </div>
            </div>
            <div className="font-normal text-[16px] leading-[26px]">
                In questa cartella puoi accedere a tutti i documenti condivisi con la tua Medical Equipe,
                oltre ai risultati dei tuoi test e alle risposte alle domande che ti vengono sottoposte
                con i questionari.
            </div>
            <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
                {folderConfig.map(({ folder, eventType, route }) => (
                    <FolderBox
                        key={folder}
                        folder={folder}
                        onClick={() => handleFolderClick(route, eventType)}
                    />
                ))}
            </div>
            <div className="p-[10px]" />
        </div>
    )
}

export default TestsAndReports
