import { Route, Routes } from 'react-router-dom';
import './App.css';
import ForgotEmail from './pages/loginFlow/forgotPassword/ForgotEmail';
import ForgotPassword from './pages/loginFlow/forgotPassword/ForgotPassword';
import ForgotPasswordInsertCode from './pages/loginFlow/forgotPassword/ForgotPasswordInsertCode';
import LoginPage from './pages/loginFlow/login/LoginPage';

import { ToastContainer } from 'react-toastify';
import 'typeface-roboto';
import ChangePasswordSuccess from './pages/loginFlow/forgotPassword/ChangePasswordSuccess';
import NewPassword from './pages/loginFlow/forgotPassword/NewPassword';
import EmailRegistration from './pages/loginFlow/registration/EmailRegistration';
import EmailRegistrationInsertCode from './pages/loginFlow/registration/EmailRegistrationInsertCode';
import RegistrationSuccess from './pages/loginFlow/registration/RegistrationSuccess';
import CheckupAssessmentHomePage from './pages/new-platform/check-up-survey/CheckupAssessmentHomePage';
import CheckupAssessmentSurvey from './pages/new-platform/check-up-survey/CheckupAssessmentSurvey';
import Diagnostica from './pages/new-platform/diagnostica/Diagnostica';
import DnaTest from './pages/new-platform/diagnostica/dna-test/DnaTest';
import Packing from './pages/new-platform/diagnostica/dna-test/Packing';
import RegisterSwabCode from './pages/new-platform/diagnostica/dna-test/RegisterSwabCode';
import RequestPickup from './pages/new-platform/diagnostica/dna-test/RequestPickup';
import Tutorial from './pages/new-platform/diagnostica/dna-test/Tutorial';
import EditQuestionPage from './pages/new-platform/diagnostica/health-assessment/EditQuestionPage';
import HealthAssessment from './pages/new-platform/diagnostica/health-assessment/HealthAssessment';
import HealthAssessmentCategory from './pages/new-platform/diagnostica/health-assessment/HealthAssessmentCategory';
import HealthAssessmentHomePage from './pages/new-platform/diagnostica/health-assessment/HealthAssessmentHomePage';
import HealthAssessmentSuccessPage from './pages/new-platform/diagnostica/health-assessment/HealthAssessmentSuccessPage';
import HealthAssessmentSurvey from './pages/new-platform/diagnostica/health-assessment/HealthAssessmentSurvey';
import TestsAndReports from './pages/new-platform/diagnostica/TestsAndReports';
import YourAppointments from './pages/new-platform/equipe/YourAppointments';

import FetchData from './pages/loginFlow/login/FetchData';
import BloodPacking from './pages/new-platform/diagnostica/blood-test/BloodPacking';
import BloodRequestPickup from './pages/new-platform/diagnostica/blood-test/BloodRequestPickup';
import BloodTest from './pages/new-platform/diagnostica/blood-test/BloodTest';
import BloodTutorial from './pages/new-platform/diagnostica/blood-test/BloodTutorial';
import RegisterSample from './pages/new-platform/diagnostica/blood-test/RegisterSample';
import ResultsAndProgresses from './pages/new-platform/diagnostica/ResultsAndProgresses';
import YourExperts from './pages/new-platform/equipe/YourExperts';
import HealthPlan from './pages/new-platform/health-plan/HealthPlan';
import MealPlan from './pages/new-platform/health-plan/MealPlan';
import Diet from './pages/new-platform/health-plan/single-pdfs/Diet';
import EatingHabits from './pages/new-platform/health-plan/single-pdfs/EatingHabits';
import SeasonalFruitsAndVegetables from './pages/new-platform/health-plan/single-pdfs/SeasonalFruitsAndVegetables';
import ShoppingList from './pages/new-platform/health-plan/single-pdfs/ShoppingList';
import TipsAndRecepes from './pages/new-platform/health-plan/single-pdfs/TipsAndRecepes';
import HowItWorksConsults from './pages/new-platform/menu/HowItWorksConsults';
import HowItWorksDna from './pages/new-platform/menu/HowItWorksDna';
import HowItWorksSubHealth from './pages/new-platform/menu/HowItWorksSubHealth';
import HowItWorksSubNutrition from './pages/new-platform/menu/HowItWorksSubNutrition';
import PaymentMethodsAndPurchases from './pages/new-platform/menu/PaymentMethodsAndPurchases';
import Redeem from './pages/new-platform/onboarding/Redeem';

import CheckPurchases from './pages/loginFlow/login/CheckPurchases';
import FetchUserData from './pages/loginFlow/login/FetchUserData';
import { NavBarElement } from './pages/new-platform/components/NavBarElement';
import PlatformPageLayout from './pages/new-platform/components/PlatformPageLayout';
import UploadedForYou from './pages/new-platform/diagnostica/uploads/UploadedForYou';
import UploadedFromYou from './pages/new-platform/diagnostica/uploads/UploadedFromYou';
import Equipe from './pages/new-platform/equipe/Equipe';
import HomePage from './pages/new-platform/HomePage';
import MenuRedeem from './pages/new-platform/menu/MenuRedeem';
import PersonalData from './pages/new-platform/menu/PersonalData';
import PurchasePage from './pages/new-platform/menu/PurchasePage';
import PharmaciesRedeemSwabcode from './pages/new-platform/onboarding/PharmaciesRedeemSwabcode';
import Sorting from './pages/new-platform/onboarding/Sorting';

const App: React.FC = () => {
  return (
    <>
      <Routes>
        <Route path='/' element={<LoginPage />} />
        <Route path='login' element={<LoginPage />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='forgot-email' element={<ForgotEmail />} />
        <Route path='forgot-password-insert-code' element={<ForgotPasswordInsertCode />} />
        <Route path='new-password' element={<NewPassword />} />
        <Route path='email-registration' element={<EmailRegistration />} />
        <Route path='email-registration-code' element={<EmailRegistrationInsertCode />} />
        <Route path='registration-success' element={<RegistrationSuccess />} />
        <Route path='check-purchases' element={<CheckPurchases />} />
        <Route path='fetch-user-data' element={<FetchUserData />} />
        <Route path='fetch-data' element={<FetchData />} />
        <Route path='change-password-success' element={<ChangePasswordSuccess />} />
        <Route path='redeem' element={<Redeem />} />
        <Route path='pharmacies-redeem-swabcode' element={<PharmaciesRedeemSwabcode />} />
        <Route path='sorting' element={<Sorting />} />
        <Route
          path='home'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.Home}
            >
              <HomePage />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <HealthPlan />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan/meal-plan'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <MealPlan />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan/meal-plan/diet'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <Diet />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan/meal-plan/shopping-list'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <ShoppingList />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan/meal-plan/tips-and-recepes'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <TipsAndRecepes />
            </PlatformPageLayout>
          }
        />
        <Route
          path='health-plan/meal-plan/eating-habits'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <EatingHabits />
            </PlatformPageLayout>
          } />
        <Route
          path='health-plan/meal-plan/seasonal-fruits-and-vegetables'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.HealthPlan}
            >
              <SeasonalFruitsAndVegetables />
            </PlatformPageLayout>
          }
        />
        <Route
          path='diagnostica'
          element={
            <PlatformPageLayout
              currentItem={NavBarElement.Diagnostica}
            >
              <Diagnostica />
            </PlatformPageLayout>
          }
        />
        <Route
          path='diagnostica/results-and-progresses'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <ResultsAndProgresses />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <TestsAndReports />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/dna-test'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <DnaTest />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/dna-test/tutorial'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <Tutorial />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/dna-test/register-swab-code'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <RegisterSwabCode />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/dna-test/packing'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <Packing />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/dna-test/request-pickup'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <RequestPickup />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/blood-test'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <BloodTest />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/blood-test/tutorial'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <BloodTutorial />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/blood-test/sample-register'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <RegisterSample />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/blood-test/packing' 
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <BloodPacking />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/blood-test/request-pickup'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <BloodRequestPickup />
          </PlatformPageLayout>
          }
          />
        <Route
          path='diagnostica/tests-and-reports/uploaded-for-you'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <UploadedForYou />
          </PlatformPageLayout>
          } />
        <Route
          path='diagnostica/tests-and-reports/uploaded-from-you'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <UploadedFromYou />
          </PlatformPageLayout>
          }
          />
        <Route
          path='health-assessment'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <HealthAssessment />
          </PlatformPageLayout>
          }
          />
        <Route
          path='health-assessment/category/:category'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Diagnostica}
          >
            <HealthAssessmentCategory />
          </PlatformPageLayout>
        }
          />
        <Route
          path='equipe'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Equipe}
          >
            <Equipe />
          </PlatformPageLayout>
          }
          />
        <Route
          path='equipe/your-appointments'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Equipe}
          >
            <YourAppointments />
          </PlatformPageLayout>
          }
          />
        <Route
          path='equipe/your-experts'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.Equipe}
          >
            <YourExperts />
          </PlatformPageLayout>
          }
          />
        <Route path='health-assessment-home-page' element={<HealthAssessmentHomePage />} />
        <Route path='health-assessment-survey' element={<HealthAssessmentSurvey />} />
        <Route path='health-assessment-success-page' element={<HealthAssessmentSuccessPage />} />
        <Route path='check-up-assessment-home-page' element={<CheckupAssessmentHomePage />} />
        <Route path='check-up-assessment-survey' element={<CheckupAssessmentSurvey />} />
        <Route path='edit-question' element={<EditQuestionPage />} />
        <Route
          path='menu/personal-data'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.MenuElement}
          >
            <PersonalData />
          </PlatformPageLayout>
          }
          />
        <Route
          path='menu/payment-methods-and-purchases'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.MenuElement}
          >
            <PaymentMethodsAndPurchases />
          </PlatformPageLayout>
          }
          />
        <Route path='menu/payment-methods-and-purchases/redeem' element={<MenuRedeem />} />
        <Route
          path='purchase'
          element={
            <PlatformPageLayout
            currentItem={NavBarElement.MenuElement}
          >
            <PurchasePage />
          </PlatformPageLayout>
          }
          />
        <Route path='how-it-works-dna' element={<HowItWorksDna />} />
        <Route path='how-it-works-sub-health' element={<HowItWorksSubHealth />} />
        <Route path='how-it-works-sub-nutrition' element={<HowItWorksSubNutrition />} />
        <Route path='how-it-works-consults' element={<HowItWorksConsults />} />
        {/* <Route index element={<TopPage />} /> */}
        <Route path="*" element={<p>Page Not Found</p>} />
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};


export default App;
