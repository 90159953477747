import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { saveEvent } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import CloseButton from "../../components/CloseButton"

const Packing = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const onConfirmClick = () => {
        if (!email) return
        navigate("/diagnostica/tests-and-reports/dna-test/request-pickup")
        saveEvent(
            email,
            EventType.DnaPackingButtonClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_packing_page",
        )
    }

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_packing_page",
        )
    }, [])

    return (
        <div className="w-full h-full flex flex-col flex-shrink-0">
            <div className="w-full my-[10px] flex justify-end flex-shrink-0">
                <CloseButton
                    onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
                />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px] flex-shrink-0">
                Impacchetta il tuo test
            </div>
            <div className="flex-1 flex flex-col justify-between font-normal text-[16px] leading-[26px]">
                <div className="space-y-[30px]">
                    <div>
                        <div className="font-bold">
                            1. Compila i due sticker codice campione:
                        </div>
                        <ul className="list-disc ml-[20px]">
                            <li>Indica data e orario di prelievo.</li>
                            <li>Incolla uno sticker sulla tua Member's Card.<br />(👉 Tienila con te, non spedirla.)</li>
                            <li>Incolla il secondo sticker sulla provetta.</li>
                        </ul>
                    </div>
                    <div>
                        <div className="font-bold">
                            2. Prepara la spedizione:
                        </div>
                        <ul className="list-disc ml-[20px]">
                            <li>
                                Inserisci la provetta nella busta rinforzata/biohazard.
                            </li>
                            <li>
                                Chiudi la busta e inseriscila nella busta del corriere.
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="mb-[20px] md:mb-[50px] w-full space-y-[10px] justify-between md:space-y-0 md:space-x-[10px] md:flex md:flex-row md:items-center">
                    <div className="w-full h-[50px] flex-shrink-0 md:w-[49%]">
                        <PrimaryButton
                            text="Fatto! Avanti"
                            pointer={true}
                            disabled={false}
                            fontSize={16}
                            onClick={onConfirmClick}
                        />
                    </div>
                    <div className="w-full h-[50px] flex-shrink-0 md:w-[49%]">
                        <WhiteButton
                            text="Indietro"
                            pointer={true}
                            fontSize={16}
                            onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
                            uppercase={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Packing
