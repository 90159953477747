import { useEffect, useRef } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../components/buttons/PrimaryButton"
import { saveEvent } from "../../utils/apis/user-service"
import { useAuth } from "../../utils/context/AuthContext"
import { useHealthPlan } from "../../utils/context/HealthPlanContext"
import { useTutorial } from "../../utils/context/TutorialContext"
import { useUserData } from "../../utils/context/UserDataContext"
import { useProducts } from "../../utils/context/UserProductsContext"
import { EventType } from "../../utils/enums/EventType"
import { TutorialType } from "../../utils/enums/TutorialType"
import { UpsellCardType } from "../../utils/enums/UpsellCard"
import ControlPanel from "./components/ControlPanel"
import UpsellCard from "./components/UpsellCard"
import { HealthPlanCard, HPCategory } from "./health-plan/HealthPlan"

const HomePage = () => {
  const { userData } = useUserData()
  const email = userData.email

  const { auth } = useAuth()
  const idToken = auth.idToken
  const { healthPlan } = useHealthPlan()
  const hPlan = healthPlan.healthPlan
  const topHeroFood = hPlan?.data.top_hero_foods
  const topIntegratori = hPlan?.data.top_integratori
  const nutriRecommendations = hPlan?.data.nutrition_recommendations
  const { productsData } = useProducts()
  const dnatest = productsData.dnaTest
  const membership = productsData.membership

  const dnaTestUser = dnatest && !membership


  // Ref for the Upsell card
  const upsellCardRef = useRef<null | HTMLDivElement>(null)

  // Scroll to Upsell card
  const scrollToUpsellCard = () => {
    if (upsellCardRef.current) {
      upsellCardRef.current.scrollIntoView({ behavior: "smooth" })
    }
    if (!email) return
    saveEvent(
      email,
      EventType.ScrollToUpsellCardButtonClicked,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "home_page"
    )
  }

  useEffect(() => {
    if ( !email) return

    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "home_page"
    )

  }, [])

  const shouldShowHealthPlanHints = nutriRecommendations || topHeroFood || topIntegratori

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col space-y-[20px]">
      <Header onScrollButtonClick={scrollToUpsellCard} />
      <ControlPanel />
      {shouldShowHealthPlanHints && <MealPlanSection />}
      {
        dnaTestUser &&
        <div ref={upsellCardRef}>
          <UpsellCard
            cardType={UpsellCardType.Card}
            source_page="home_page"
          />
        </div>
      }
      <div className="h-[20px] flex-shrink-0"/>
    </div>
  )
}

export default HomePage

const Header: React.FC<{
  onScrollButtonClick: () => void,
}> = ({
  onScrollButtonClick,
}) => {
    const { userData } = useUserData()
    const username = userData.name
    const { tutorial } = useTutorial()
    const tutorialHP = tutorial.tutorial
    const { productsData } = useProducts()
    const dnatest = productsData.dnaTest
    const membership = productsData.membership

    const dnaTestUser = dnatest && !membership

    const shouldShowTutorial = tutorialHP !== null && tutorialHP.tutorial_type !== TutorialType.CONSULT && tutorialHP.confirmed === false

    const navigate = useNavigate()

    const onHowItWorksClick = () => {
      if (!tutorialHP) return
      switch (tutorialHP.tutorial_type) {
        case TutorialType.DNA_TEST:
          return navigate("/how-it-works-dna")
        case TutorialType.CONSULT:
          return navigate("/how-it-works-consults")
        case TutorialType.SUB_HEALTH:
          return navigate("/how-it-works-sub-health")
        case TutorialType.SUB_NUTRITION:
          return navigate("/how-it-works-sub-nutrition")
      }
    }

    // ********************************************************************************

    return (
      <div className={`w-full flex flex-col items-start justify-start font-bold text-[25px] leading-[33px] ${(shouldShowTutorial || dnaTestUser) && "border-b border-solid border-black"} md:flex-row md:justify-between md:items-center ${tutorial && "md:pb-[30px]"}`}>
        <div className={`${tutorial && "mb-[10px]"} md:mb-0`}>
          Ciao {username}! {"\u{1F60E}"}
        </div>
        {
          shouldShowTutorial && !dnaTestUser &&
          <div className="w-full h-[50px] mb-[20px] md:w-[362px] md:mb-0">
            <PrimaryButton
              text="Come funziona"
              pointer={true}
              disabled={false}
              uppercase={false}
              onClick={onHowItWorksClick}
            />
          </div>
        }
        {
          dnaTestUser &&
          <div className="w-full h-[50px] mb-[20px] md:w-[362px] md:mb-0">
            <PrimaryButton
              text="Ottieni il massimo dai tuoi dati genetici  ️⬇️"
              pointer={true}
              disabled={false}
              uppercase={false}
              onClick={onScrollButtonClick}
            />
          </div>
        }
      </div>
    )
  }

const MealPlanSection: React.FC<{

}> = ({

}) => {
    const { healthPlan } = useHealthPlan()
    const hPlan = healthPlan.healthPlan
    const topHeroFood = hPlan?.data.top_hero_foods
    const topIntegratori = hPlan?.data.top_integratori
    const nutriRecommendations = hPlan?.data.nutrition_recommendations

    // ********************************************************************************

    return (
      <div className="flex flex-col md:flex-row md:justify-between">
        {
          nutriRecommendations && nutriRecommendations.length > 0 && <HealthPlanCard inputs={nutriRecommendations} type={HPCategory.NutriRecommendations} />
        }
        {
          topHeroFood && topHeroFood.length > 0 && <HealthPlanCard inputs={topHeroFood} type={HPCategory.Food} />
        }
        {
          topIntegratori && topIntegratori.length > 0 && <HealthPlanCard inputs={topIntegratori} type={HPCategory.Integratori} />
        }
      </div>
    )
  }
