
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../utils/context/DNATestTrackerContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { TrackingStatus } from "../../../utils/enums/TrackingStatus"
import { UpsellCardItem, UpsellCardType } from "../../../utils/enums/UpsellCard"
import CloseButton from "./CloseButton"

interface Props {
    cardType: UpsellCardType,
    onCloseClick?: () => void,
    source_page?: string,
}

const UpsellCard: React.FC<Props> = ({
    cardType,
    onCloseClick,
    source_page,
}) => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const name = userData.name
    const family_name = userData.familyName
    const { dnaTestTracker } = useDNATestTracker()
    const status = dnaTestTracker.status

    const handleUpsellLink = () => {
        switch (cardType) {
            case UpsellCardType.Card:
                return "https://www.holifya.com/products/percorso-health?utm_source=holifyaplatform&utm_medium=inplatform&utm_campaign=card&utm_id=pushmembership"
            case UpsellCardType.Popup:
                return "https://www.holifya.com/products/percorso-health?utm_source=holifyaplatform&utm_medium=inplatform&utm_campaign=popup&utm_id=pushmembership"
        }
    }

    const handleEventType = () => {
        switch (cardType) {
            case UpsellCardType.Card:
                return EventType.UpsellCardClicked
            case UpsellCardType.Popup:
                return EventType.UpsellPopupClicked
        }
    }

    const onButtonClick = () => {
        window.open(`${handleUpsellLink()}`, "_blank")
        if (!email || !userId || !name || !family_name) return
        saveEvent(
            email,
            handleEventType(),
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            source_page,
            userId,
            status === TrackingStatus.ReportGenerated,
            name,
            family_name,
        )
    }

    const onDismissClick = () => {
        if (!onCloseClick) return
        onCloseClick()
        if (!email) return
        saveEvent(
            email,
            EventType.DismissUpsellPopupClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_test_page",
        )
    }

    // ********************************************************************************

    return (
        <div className={`w-full flex flex-col rounded-[15px] shadow-platform`}>
            <div
                className="w-full rounded-t-[15px] p-[20px] flex justify-between"
                style={{
                    background: "linear-gradient(90deg, rgba(247, 248, 248, 1), rgba(225, 227, 228, 1))"
                }}
            >
                <div className="font-bold text-[20px] leading-[25px] md:text-[30px] md:leading-[39px]">
                    Sblocca ora il<br /> Percorso Health!
                </div>
                {
                    cardType === UpsellCardType.Popup && onCloseClick &&
                    <CloseButton onClick={onDismissClick} color="black" />
                }
            </div>
            <div className="p-[20px]">
                <UpsellCardItems />
                <div className="w-full h-[1px] bg-black my-[20px]" />
                <div className="flex flex-col items-center">
                    <div className="font-bold text-[16px] leading-[23px] text-center mb-[20px]">
                        Sblocca la Membership - Percorso Health a un prezzo mai visto!
                    </div>
                    <div className="w-full h-[50px]">
                        <PrimaryButton
                            text="Sblocca"
                            pointer={true}
                            disabled={false}
                            onClick={onButtonClick}
                        />
                    </div>
                </div>
                <div className="w-full h-[1px] bg-black my-[20px]" />
                <div className="font-medium text-[15px] leading-[22px]">
                    Per riscattare e gestire gli acquisti con questo account assicurati di utilizzare la mail <span className="font-bold text-holifya-blue">{email}</span> al momento dell'acquisto.
                </div>
            </div>
        </div>
    )
}

export default UpsellCard

const UpsellCardItems = () => {
    return (
        <div>
            <UpsellCardSingleItem
                item={UpsellCardItem.DnaTest}
                isEdge={true}
            />
            <div className="relative mt-[30px]">
                <div className="rounded-[5px] border-l border-solid border-holifya-blue pl-[10px] py-[15px]">
                    <UpsellCardSingleItem
                        item={UpsellCardItem.BloodTest}
                        isEdge={true}
                    />
                    <UpsellCardSingleItem
                        item={UpsellCardItem.HealthScore}
                        isEdge={false}
                    />
                    <UpsellCardSingleItem
                        item={UpsellCardItem.Consults}
                        isEdge={false}
                    />
                    <UpsellCardSingleItem
                        item={UpsellCardItem.HealthPlan}
                        isEdge={true}
                    />
                </div>
                <div className="absolute top-0 left-0 border-t border-solid border-holifya-blue rounded-l-[5px] text-transparent text-[10px]">
                    p
                </div>
                <div className="absolute bottom-0 left-0 border-b border-solid border-holifya-blue rounded-l-[5px] text-transparent text-[10px]">
                    p
                </div>
                <div className="absolute top-[-6px] left-[20px] text-[12px] font-bold leading-[17px] text-holifya-blue">
                    Percorso Health
                </div>
            </div>
        </div>
    )
}

interface ItemProps {
    item: UpsellCardItem,
    isEdge: boolean,
}

const UpsellCardSingleItem: React.FC<ItemProps> = ({
    item,
    isEdge,
}) => {
    const handleName = () => {
        switch (item) {
            case UpsellCardItem.DnaTest:
                return "🧬  DNA Test Complete"
            case UpsellCardItem.BloodTest:
                return "🩸  Analisi degli Aminoacidi"
            case UpsellCardItem.HealthScore:
                return "📊  Health score"
            case UpsellCardItem.Consults:
                return "👩‍⚕️  Supporto medico continuo"
            case UpsellCardItem.HealthPlan:
                return "📑  Health Plan personalizzato"
        }
    }

    // ********************************************************************************

    return (
        <div className={`flex items-center justify-between ${!isEdge && "my-[10px]"}`}>
            <div className="flex">
                <img src={`/images/upsell-card/${item === UpsellCardItem.DnaTest ? "check" : "add"}.svg`} className="mr-[10px]" />
                <div className="font-medium text-[12px] leading-[21px]">
                    {handleName()}
                </div>
            </div>
            <div className={`font-bold text-[9px] leading-[13px] uppercase ${item !== UpsellCardItem.DnaTest && "text-holifya-blue"}`}>
                {item === UpsellCardItem.DnaTest ? "Risultati ricevuti" : "Incluso!"}
            </div>
        </div>
    )
}
