import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import LoadingSpinner from "../../../components/LoadingSpinner";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";

const ChangePasswordSuccess = () => {

    const { login } = useAuth();

    const navigate = useNavigate();
    const location = useLocation()
    const email = location.state.email
    const password = location.state.password

    const callLogin = async (email: string, password: string) => {
        try {
            const result = await loginApi(email, password)
            if (result.idToken && result.accessToken && result.userId && result.refreshToken && result.expiryTime) {
                login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                navigate("/fetch-user-data");
            } else {
                throw new Error("Missing token or user information in the response.");
            }
        } catch (error) {
            navigate("/login")
        }
    }

    useEffect(() => {
        setTimeout(() => {
            callLogin(email, password)
        }, 1500)
    }, [])

    // ********************************************************************************

    return (
        <div className={`App`}>
            <div className="w-[90%] h-full md:w-[392px] flex items-center justify-center">
                <div className="w-full py-[20px] bg-white flex flex-col items-center space-y-[20px]">
                    <div className="font-bold text-[30px] leading-[38px] text-center">
                        Nuova password impostata correttamente
                    </div>
                    <div className="font-normal text-[18px] leading-[26px] text-center">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordSuccess
