import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../../utils/api-objects/Exam"
import { getAllMealPlanExamsByType } from "../../../../utils/apis/emr-service"
import { saveEvent } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../../utils/enums/BucketFolder"
import { EventType } from "../../../../utils/enums/EventType"
import LoadingSpinner from "../../components/Loaders"
import BackButton from "./../../components/BackButton"
import { PdfCard, PdfType } from "./../../components/PdfCard"

const Diet = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()

  const [reports, setReports] = useState<Exam[]>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!userId || !idToken) {
      return
    }
    getAllMealPlanExamsByType(
      userId,
      idToken,
      BucketFolder.Diet,
      (exams) => {
        setReports(exams)
        setIsLoading(false)
      },
      (error) => {
        console.log("ekjhfkjeflwejfpiwe")
        console.error(error)
      }
    );

    if (!email) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "diet_page"
    )
  }, []);

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col space-y-[20px]">
      <div className="mt-[10px] space-y-[10px]">
        <BackButton
          text="Torna a Meal Plan"
          onClick={() => navigate("/health-plan/meal-plan")}
        />
        <div className="font-bold text-[25px] leading-[33px]">
          Dieta
        </div>
      </div>
      <div className="flex flex-wrap gap-4">
        {
          reports && reports.length > 0 ? reports.map(report => (
            <PdfCard key={report.filename} exam={report} pdfType={PdfType.Diet} />
          )) :
            <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
              Il tuo piano alimentare sarà disponibile dopo il tuo consulto con nutrizionista.
            </div>
        }
      </div>
      <div className="p-[10px]" />
      {
        isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>
      }
    </div>
  )
}

export default Diet
