import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import HolifyaFullLogo from "../../../components/HolifyaFullLogo";
import { getAppointments, readsConsultsLeft } from "../../../utils/apis/booking-service";
import { getBloodTrackerByUserId, getTrackerByUserId, updateTrackerByOrderId } from "../../../utils/apis/dna-test-tracker";
import { getMealPlan } from "../../../utils/apis/plan-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useBloodTestTracker } from "../../../utils/context/BloodTextTrackerContext";
import { useConsults } from "../../../utils/context/ConsultsContext";
import { useDNATestTracker } from "../../../utils/context/DNATestTrackerContext";
import { useExpert } from "../../../utils/context/ExpertContext";
import { useHealthPlan } from "../../../utils/context/HealthPlanContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { AppointmentStatus, ConsultType } from "../../../utils/enums/Consults";
import { TrackingStatus } from "../../../utils/enums/TrackingStatus";
import { handleDaysDifference, handleHoursDifference, handleMinutesDifference } from "../../../utils/Functions";
import { Appointment } from "./Appointment";
import NavBar from "./NavBar";
import { NavBarElement } from "./NavBarElement";
import SessionExpiredDialog from "./SessionExpiredDialog";

interface PlatformPageProps {
    currentItem: NavBarElement;
    children: React.ReactNode
}

const PlatformPageLayout: React.FC<PlatformPageProps> = ({
    currentItem,
    children,
}) => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { consultsData, updateSpecialistConsultsLeft, updateNutritionistConsultsLeft, updateAllAppointments, updateAllFutureAppointments, updateAllPastAppointments, updateMostRecentFutureAppointment, updateNutritionistFutureConsults, updateSpecialistFutureConsults } = useConsults()
    const { experts } = useExpert()
    const nutritionist = experts.nutritionist
    const specialist = experts.specialist
    const { updateHealthPlan } = useHealthPlan()
    const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
    const status = dnaTestTracker.status
    const orderId = dnaTestTracker.orderId
    const { bloodTestTracker, updateBloodTestTracker } = useBloodTestTracker()

    const [shouldShowExpirySessionDialog, setShouldShowExpirySessionDialog] = useState(false)

    const contentRef = useRef<HTMLDivElement>(null);

    const [isLoading, setIsLoading] = useState(false)

    const handleSessionExpiry = () => {
        if (!shouldShowExpirySessionDialog) {
            setShouldShowExpirySessionDialog(true);
        }
    };


    const updateTracker = (status: TrackingStatus, orderId: string) => {
        if (!idToken) return

        updateTrackerByOrderId(
            orderId,
            idToken,
            status,
            (tracker) => {
                const newStatus = tracker.tracking_status
                updateDNATestTracker(newStatus, orderId)
            },
            (error) => {
                console.log(error)
            },
        )
    }

    const fetchAppointments = async () => {
        if (!idToken || !email) return;

        try {
            const appointments = await getAppointments(idToken, email);
            if (appointments) {
                const allAppointments: Appointment[] = appointments.map((appointment) => ({
                    date: appointment.payload.scheduled_event.start_time,
                    editUrl: appointment.payload.reschedule_url,
                    connectUrl: appointment.payload.scheduled_event.location.join_url,
                    cancelUrl: appointment.payload.cancel_url,
                    remainingDays: handleDaysDifference(appointment.payload.scheduled_event.start_time),
                    remainingHours: handleHoursDifference(appointment.payload.scheduled_event.start_time),
                    remainingMinutes: handleMinutesDifference(appointment.payload.scheduled_event.start_time),
                    expert: appointment.organizer_email === "elvira.pistolesi@holifya.com" ? specialist! : nutritionist!,
                    status: appointment.payload.status,
                }));
                const allActiveAppointments = allAppointments.filter(
                    (appointment) => appointment.status === AppointmentStatus.Active
                );
                const futureAppointments = allActiveAppointments.filter(
                    (appointment) => appointment.remainingMinutes > -30
                );
                const pastAppointments = allActiveAppointments.filter(
                    (appointment) => appointment.remainingMinutes <= -30
                );
                updateAllAppointments(allActiveAppointments);
                updateAllFutureAppointments(futureAppointments);
                updateMostRecentFutureAppointment(futureAppointments[0] || null);
                updateAllPastAppointments(pastAppointments);
                const expertFutureConsults = futureAppointments.filter(
                    (consult) => consult.expert?.email === "elvira.pistolesi@holifya.com"
                );
                const nutritionistFutureConsults = futureAppointments.filter(
                    (consult) => consult.expert?.email !== "elvira.pistolesi@holifya.com"
                );
                updateNutritionistFutureConsults(nutritionistFutureConsults.length ? nutritionistFutureConsults : null);
                updateSpecialistFutureConsults(expertFutureConsults.length ? expertFutureConsults : null);
            }
        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes("Session expired")) {
                    throw new Error("Session expired");
                } else if (error.message.includes("Nessun appuntamento trovato")) {
                    throw new Error(error.message); 
                }
            } else {
                throw new Error("Unexpected error on get appointments");
            }
        }
    }

    const fetchNutriConsultsLeft = async () => {
        if (!idToken || !userId) return
        try {
            const consults_left = await readsConsultsLeft(
                userId,
                idToken,
                ConsultType.Nutritionist,
            )
            if (consultsData.nutritionistConsultsLeft !== consults_left) {
                updateNutritionistConsultsLeft(consults_left)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchExpertConsultsLeft = async () => {
        if (!idToken || !userId) return
        try {
            const consults_left = await readsConsultsLeft(
                userId,
                idToken,
                ConsultType.Specialist,
            )
            if (consultsData.specialistConsultsLeft !== consults_left) {
                updateSpecialistConsultsLeft(consults_left)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const fetchMealPlan = async () => {
        if (!idToken || !userId) return
        try {
            const mealPlan = await getMealPlan(userId, idToken)
            updateHealthPlan(mealPlan)
        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes("Session expired")) {
                    throw new Error("Session expired");
                } else if (error.message.includes("Nessun meal plan trovato")) {
                    throw new Error(error.message); 
                }
            } else {
                throw new Error("Unexpected error on get appointments");
            }
            updateHealthPlan(null);
        }
    }

    const fetchDnaTracker = async () => {
        if (!userId || !idToken) return
        getTrackerByUserId(userId, idToken)
            .then((trackers) => {
                if (trackers.length > 0) {
                    const tracker = trackers[0];
                    const newStatus = tracker.tracking_status;
                    const orderId = tracker.order_id;
                    if (status !== newStatus || status === null) {
                        updateTracker(newStatus, orderId);
                    }
                }
            })
            .catch((error) => {
                if (error instanceof Error) {
                    console.error("Error fetching trackers:", error.message);
                } else {
                    console.error("Unexpected error fetching trackers:", error);
                }
            });
    }

    const fetchBloodTracker = async () => {
        if (!userId || !idToken) return;

        try {
            const tracker = await getBloodTrackerByUserId(userId, idToken);

            const newBloodStatus = tracker.tracking_status;
            updateBloodTestTracker(newBloodStatus);
        } catch (error) {
            if (error instanceof Error) {
                console.log(error.message);
            }
        }
    }

    const fetchAllData = async () => {
        if (!idToken || !userId || !email) return
        setIsLoading(true)
        try {
            await Promise.all([
                fetchAppointments(),
                fetchNutriConsultsLeft(),
                fetchExpertConsultsLeft(),
                fetchMealPlan(),
                fetchDnaTracker(),
                fetchBloodTracker(),
            ]
            )
        } catch (error) {
            if (error instanceof Error) {
                if (error.message.includes("Session expired") || error.message.includes("401")) {
                    console.error("Session expired detected in fetchAllData.");
                    handleSessionExpiry();
                } else if (error.message.includes("Nessun appuntamento trovato")) {
                    console.error(error);
                } else {
                    console.error("An unknown error occurred:", error);
                }
            } else {
                console.error("An unknown error occurred:", error);
            }
        } finally {
            setIsLoading(false);
        }
    }

    const navigate = useNavigate()

    useEffect(() => {
        fetchAllData()
        const intervalId = setInterval(fetchAllData, 1 * 60 * 1000)
        return () => clearInterval(intervalId)
    }, [])

    // ********************************************************************************

    return (
        <div className="w-full h-[100dvh] flex flex-col-reverse md:flex-row">
            <NavBar
                currentItem={currentItem}
            />
            <div className="flex-1 h-full flex flex-col md:pt-[70px] overflow-hidden">
                <div className="px-[50px] hidden md:block">
                    <HolifyaFullLogo />
                </div>
                <div
                    ref={contentRef}
                    className="flex-1 w-full overflow-y-auto scrollbar-hide px-[20px] md:px-[50px] pt-[50px] md:pt-[20px]"
                >
                    {children}
                </div>
            </div>
            {
                shouldShowExpirySessionDialog &&
                <SessionExpiredDialog
                    onClick={() => navigate("/")}
                />
            }
            {/* {
                isLoading && <LoadingOverlay />
            } */}
        </div>
    );
};

export default PlatformPageLayout;
