import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import WhiteButtonBlackBorder from "../../../components/buttons/WhiteButtonBlackBorder";
import { getUserInfoApi } from "../../../utils/apis/user-service";
import { HOLIFYA_LOGO } from "../../../utils/Constants";
import { useAuth } from "../../../utils/context/AuthContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import LoadingSpinner from "../../new-platform/components/Loaders";

const FetchUserData = () => {
    const navigate = useNavigate();
    const { auth } = useAuth();
    const idToken = auth.idToken
    const userId = auth.userId
    const { updateUserData, updateSwabcode, updateBloodcodes } = useUserData()

    const [errorFetchingUserData, setErrorFetchingUserData] = useState(false)

    const fetchUserData = async () => {
        if (!userId || !idToken) return
        try {
            const user = await getUserInfoApi(userId, idToken)
            updateUserData(user.data.name, user.data.family_name, user.email, user.data.mobile_phone, user.tax_number)
            updateSwabcode(user.swabcode)
            updateBloodcodes(user.bloodcodes)
            navigate("/check-purchases")
        } catch (error) {
            console.error("Error fetching user data:", error instanceof Error ? error.message : error);
            setErrorFetchingUserData(true)
        }
    }

    useEffect(() => {
        fetchUserData()
    }, [])

    // ********************************************************************************

    return (
        <div className="NewApp">
            <div className="w-full h-full flex items-center justify-center">
                <div className="flex flex-col items-center px-[20px]">
                    <div className="h-[35px] mb-[40px]">
                        <img src={HOLIFYA_LOGO} alt="Holifya Logo" />
                    </div>
                    {
                        errorFetchingUserData ?
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ❌ Errore
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Qualcosa è andato storto, ti chiediamo ti riprovare
                                </div>
                                <div className="w-[300px] h-[50px]">
                                    <WhiteButtonBlackBorder
                                        text="Torna alla login page"
                                        onClick={() => navigate("/")}
                                    />
                                </div>
                            </div> :
                            <div className="flex flex-col items-center px-[20px]">
                                <div className="font-bold text-[25px] leading-[33px] mb-[20px]">
                                    ⏳ Attendi...
                                </div>
                                <div className="font-normal text-[16px] leading-[24px] text-center mb-[50px]">
                                    Stiamo cercando acquisti associati a questo indirizzo e-mail.
                                </div>
                                <div><LoadingSpinner /></div>
                            </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default FetchUserData
