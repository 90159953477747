import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { MealPlan } from '../api-objects/HealthPlan';

interface HealthPlanState {
    healthPlan: MealPlan | null;
}

// Define the shape of the health plan context
interface HealthPlanContextType {
    healthPlan: HealthPlanState;
    updateHealthPlan: (healthPlan: MealPlan | null) => void;
    clearHealthPlan: () => void;
}

// Create the health plan context with default values
const HealthPlanContext = createContext<HealthPlanContextType | undefined>(undefined);

// Create a provider component
export const HealthPlanProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [healthPlan, setHealthPlan] = useState<HealthPlanState>(() => {
        const savedHealthPlan = localStorage.getItem('healthPlan');
        return {
            healthPlan: savedHealthPlan ? JSON.parse(savedHealthPlan) as MealPlan : null,
        };
    });

    // Effect to update localStorage when health plan state changes
    useEffect(() => {
        if (healthPlan.healthPlan) {
            localStorage.setItem('healthPlan', JSON.stringify(healthPlan.healthPlan));
        } else {
            localStorage.removeItem('healthPlan');
        }
    }, [healthPlan.healthPlan]);

    // Function to update only the nutritionist
    const updateHealthPlan = (healthPlan: MealPlan | null) => {
        setHealthPlan({ healthPlan });
    };

    // Function to clear health plan
    const clearHealthPlan = () => {
        setHealthPlan({ healthPlan: null });
        localStorage.removeItem('healthPlan');
    };

    // Provide context value to children
    return (
        <HealthPlanContext.Provider value={{ healthPlan, updateHealthPlan, clearHealthPlan }}>
            {children}
        </HealthPlanContext.Provider>
    );
};

// Custom hook to use the health plan context
export const useHealthPlan = (): HealthPlanContextType => {
    const context = useContext(HealthPlanContext);
    if (context === undefined) {
        throw new Error('useHealthPlan must be used within a HealthPlanProvider');
    }
    return context;
};
