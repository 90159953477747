import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { Folder } from ".././components/Folder"
import FolderBox from ".././components/FolderBox"

const Diagnostica = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()

  const folderConfig = [
    {
      folder: Folder.Results,
      eventType: EventType.ResultsAndProgressesFolderClicked,
      route: "results-and-progresses",
    },
    {
      folder: Folder.TestsDocs,
      eventType: EventType.TestsAndReportsFolderClicked,
      route: "tests-and-reports",
    },
  ];

  const handleFolderClick = (route: string, eventType: EventType) => {
    navigate(route);
    if (!email) return;
    saveEvent(
      email,
      eventType,
      (success) => console.log(success),
      (error) => console.log(error),
      "diagnostica_page"
    );
  };

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "diagnostica_page",
    )
  }, [])

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col space-y-[20px]">
      <div className="font-bold text-[25px] leading-[33px]">
        Diagnostica
      </div>
      <div className="flex flex-col md:flex-row md:justify-between">
        {folderConfig.map(({ folder, eventType, route }) => (
          <div
            key={folder}
            className="w-full mb-[20px] md:w-[49%] md:mb-[0]"
          >
            <FolderBox
              folder={folder}
              onClick={() => handleFolderClick(route, eventType)}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Diagnostica
