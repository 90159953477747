import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import TntButton from "../../../../components/buttons/TntButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { updateTrackerByOrderId } from "../../../../utils/apis/dna-test-tracker"
import { saveEvent } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../../utils/context/DNATestTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import { PickupChoice } from "../../../../utils/enums/PickupChoice"
import { TrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"

const RequestPickup = () => {
  const { userData } = useUserData()
  const email = userData.email
  const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
  const orderId = dnaTestTracker.orderId
  const status = dnaTestTracker.status
  const { auth } = useAuth()
  const idToken = auth.idToken
  const navigate = useNavigate()

  const shouldUpdateTracker = idToken !== null && orderId !== null && status === TrackingStatus.SampleRegistered

  const onBackClick = () => {
    navigate("/diagnostica/tests-and-reports/dna-test/packing")
  }

  const [radioChoice, setRadioChoice] = useState<PickupChoice>()

  const isConfirmButtonEnabled = radioChoice !== undefined

  const warningDiv = useRef<null | HTMLDivElement>(null);

  const handleConfirmClick = () => {
    switch (radioChoice) {
      case PickupChoice.AtHome:
        window.open("https://www.tnt.it/booking/Booking.do", "_blank", "noreferrer")
        break;
      case PickupChoice.TNTPoint:
        window.open("https://www.tnt.it/contents/trova-fedex.html", "_blank", "noreferrer")
        break;
    }
    if (shouldUpdateTracker) {
      updateTrackerByOrderId(
        orderId,
        idToken,
        TrackingStatus.PickupRequested,
        (tracker) => {
          const newStatus = tracker.tracking_status
          updateDNATestTracker(newStatus, orderId)
          navigate("/diagnostica/tests-and-reports/dna-test")
        },
        (error) => {
          console.log(error)
        },
      )
    } else {
      navigate("/diagnostica/tests-and-reports/dna-test")
    }
    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.DnaRequestPickupButtonClicked,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "dna_request_pickup_page",
    )
  }

  const onRadioCardClick = (choice: PickupChoice) => {
    setRadioChoice(choice)
    setTimeout(() => {
      warningDiv.current?.scrollIntoView({ behavior: 'smooth' })
    }, 100)
  }

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "dna_request_pickup_page",
    )
  }, [])

  return (
    <div className="w-full h-full flex flex-col flex-shrink-0">
      <div className="w-full my-[10px] flex justify-end flex-shrink-0">
        <CloseButton
          onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
        />
      </div>
      <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px] flex-shrink-0">
        Richiedi il ritiro
      </div>
      <div className="font-normal text-[16px] leading-[26px]">
        <div className="font-bold">
          📌 Inserisci la lettera di vettura:
        </div>
        <ul className="list-disc ml-[20px]">
          <li>Trova la lettera di vettura inclusa nel tuo kit.</li>
          <li>Inseriscila nella tasca trasparente sulla busta del corriere.</li>
        </ul>
      </div>
      <div className="my-[20px]">
        <div className="font-bold text-[16px] leading-[26px]">
          Scegli una delle due opzioni per spedire:
        </div>
        <div className="text-[14px] leading-[26px] italic">
          Per entrambe le opzioni non ti verrà addebitato nulla
        </div>
      </div>
      <div className="w-full h-[80px] flex-shrink-0">
        <RadioCard
          choice={PickupChoice.AtHome}
          isChecked={radioChoice === PickupChoice.AtHome}
          onClick={onRadioCardClick}
        />
      </div>
      <ul className="my-[20px] list-disc ml-[20px] font-normal text-[16px] leading-[26px]">
        <li>
          Ti invieremo sul sito di FedEx TNT per prenotare il ritiro.
        </li>
        <li>
          Seleziona <em>“Mittente o destinatario convenzionato (con addebito sul Codice Cliente)”</em>
        </li>
        <li>
          (Peso: ~0,5kg; Dimensioni: 15x15x5cm).
        </li>
      </ul>
      <div className="my-[20px] font-bold text-[13px] italic">
        Oppure
      </div>
      <div className="w-full h-[80px] flex-shrink-0">
        <RadioCard
          choice={PickupChoice.TNTPoint}
          isChecked={radioChoice === PickupChoice.TNTPoint}
          onClick={onRadioCardClick}
        />
      </div>
      <ul className="my-[20px] list-disc ml-[20px]">
        <li>
          Cerca il punto più vicino e consegna la busta.
        </li>
      </ul>
      <div className="mb-[20px] w-full h-[100px] flex flex-col justify-between mt-[20px] flex-shrink-0 md:flex-row md:h-[50px]">
        <div className="w-full h-[50px] md:w-[49%]">
          <TntButton
            text="Conferma e procedi"
            pointer={isConfirmButtonEnabled}
            disabled={!isConfirmButtonEnabled}
            fontSize={16}
            uppercase={true}
            onClick={handleConfirmClick}
          />
        </div>
        <div className="w-full h-[50px] md:w-[49%]">
          <WhiteButton
            text="Indietro"
            onClick={onBackClick}
            fontSize={16}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

export default RequestPickup

export const RadioCard: React.FC<{
  choice: PickupChoice,
  isChecked: boolean,
  onClick: (choice: PickupChoice) => void,
}> = ({
  choice,
  isChecked,
  onClick,
}) => {
  return (
    <div
      className={`cursor-pointer w-full h-full rounded-[5px] flex items-center justify-center border border-solid ${isChecked ? "border-black" : "border-transparent shadow-platform"
        } md:w-[362px]`}
      onClick={() => onClick(choice)}
    >
      <div className="w-[90%] flex items-center">
        {/* Radio button outer container */}
        <div className="w-[20px] h-[20px] flex-shrink-0 border border-solid border-black rounded-full flex items-center justify-center mr-[10px]">
          {/* Radio button inner circle */}
          <div
            className={`${isChecked ? "w-[12px] h-[12px] bg-black" : "w-0 h-0"
              } rounded-full`}
          ></div>
        </div>
        {/* Choice text */}
        <div className="font-bold text-[15px] leading-[21px]">{choice}</div>
      </div>
    </div>
  );
};
