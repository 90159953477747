import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { confirmCodeApi, resendCodeApi } from "../../../utils/apis/auth-service"
import { UnconfirmDirection, useRegistrationData } from "../../../utils/context/RegistrationContext"
import { LoadingOverlay } from "../../new-platform/components/Loaders"
import RegistrationCodeBox from "./components/RegistrationCodeBox"

const EmailRegistrationInsertCode = () => {

    const navigate = useNavigate();
    const { registrationData } = useRegistrationData()
    const email = registrationData.registrationEmail

    const [codeErrorText, setCodeErrorText] = useState("")
    const [isLoading, setIsLoading] = useState(false)

    const isUserUnconfirmed = registrationData && registrationData.isUnconfirmed

    const [showUnconfirmedPopup, setShowUnconfirmedPopup] = useState(false)
    const [dialogContent, setDialogContent] = useState<JSX.Element | null>(null);

    const handleResendCode = () => {
        if (email) {
            resendCodeApi(email, () => { }, () => { });
        }
    };

    const handleInputSubmit = (inputData: { [key: string]: string }) => {
        setCodeErrorText("")
        const code = inputData.code
        setIsLoading(true)
        email !== null && confirmCodeApi(
            email,
            code,
            () => {
                setIsLoading(false)
                navigate("/registration-success")
            },
            (error) => {
                setIsLoading(false)
                console.log(error)
                setCodeErrorText(error)
            }
        )
    }

    const onPopupDismiss = () => {
        setShowUnconfirmedPopup(false)
    }

    useEffect(() => {
        setTimeout(() => {
            if (isUserUnconfirmed !== UnconfirmDirection.None) {
                setShowUnconfirmedPopup(true)
                switch (isUserUnconfirmed) {
                    case UnconfirmDirection.Registration:
                        setDialogContent(
                            <>
                                <div className="font-bold text-[14px] leading-[17px]">
                                    Non hai completato la registrazione precedente.
                                </div>
                                <div className="text-[14px] leading-[17px]">
                                    Puoi continuare la registrazione ora. Il codice di verifica è stato appena inviato.
                                </div>
                            </>
                        );
                        break;

                    case UnconfirmDirection.LoginAttempt:
                        setDialogContent(
                            <>
                                <div className="font-bold text-[14px] leading-[17px]">
                                    Hai tentato di accedere ma il tuo account non è confermato.
                                </div>
                                <div className="text-[14px] leading-[17px]">
                                    Ti abbiamo inviato un nuovo codice di verifica. Completa la registrazione per accedere.
                                </div>
                            </>
                        );
                        break;

                    case UnconfirmDirection.LoginCreateButton:
                        setDialogContent(
                            <>
                                <div className="font-bold text-[14px] leading-[17px]">
                                    Hai avviato la creazione di un account ma non hai confermato l'email.
                                </div>
                                <div className="text-[14px] leading-[17px]">
                                    Usa il codice di verifica inviato per completare la registrazione.
                                </div>
                            </>
                        );
                        break;

                    default:
                        setDialogContent(null);
                }
            }
        }, 1000)
    }, [isUserUnconfirmed])

    // ********************************************************************************

    return (
        <div className="App">
            <div className={`w-full h-full flex flex-col items-center justify-center`}>
                <div className="w-full md:w-[368px] h-fit bg-white px-[20px] box-content">
                    <RegistrationCodeBox
                        onSubmit={handleInputSubmit}
                        onResendCodeClick={handleResendCode}
                        errorText={codeErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <LoadingOverlay />
            }
            {
                showUnconfirmedPopup && dialogContent &&
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white p-4 rounded-[15px] shadow-lg flex flex-col items-center w-[90%] md:w-[400px] space-y-[20px]">
                        {dialogContent}
                        <div className="w-full h-[50px]">
                            <PrimaryButton
                                text={"Capito"}
                                pointer={true}
                                disabled={false}
                                onClick={onPopupDismiss}
                            />
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default EmailRegistrationInsertCode
