interface Props {
    onClick?: () => void;
}

const BackButton: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <button
            className={`h-[55px] flex items-center space-x-[20px] rounded-[5px] cursor-pointer border-none`}
            onClick={onClick}
            type="button"
        >
            <img src="/images/back-arrow.svg" />
            <div className="uppercase text-[12px] font-bold text-black">
                Indietro
            </div>
        </button>
    )
}

export default BackButton
