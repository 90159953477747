import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useBloodTestTracker } from './BloodTextTrackerContext';
import { useConsults } from './ConsultsContext';
import { useDNATestTracker } from './DNATestTrackerContext';
import { useExpert } from './ExpertContext';
import { useHealthPlan } from './HealthPlanContext';
import { useSurveyData } from './SurveyDataContext';
import { useTutorial } from './TutorialContext';
import { useUserData } from './UserDataContext';
import { useProducts } from './UserProductsContext';

interface AuthState {
    idToken: string | null;
    userId: string | null;
    accessToken: string | null;
    refreshToken: string | null;
    expiryTime: string | null;
}

// Define the shape of the authentication context
interface AuthContextType {
    auth: AuthState;
    login: (idToken: string, userId: string, accessToken: string, refreshToken: string, expiryIn: number) => void;
    logout: () => void;
}

// Create the authentication context with default values
const AuthContext = createContext<AuthContextType | undefined>(undefined);

// Create a provider component
export const AuthProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const navigate = useNavigate()
    const [auth, setAuth] = useState<AuthState>({
        idToken: localStorage.getItem('idToken'),
        userId: localStorage.getItem('userId'),
        accessToken: localStorage.getItem('accessToken'),
        refreshToken: localStorage.getItem('refreshToken'),
        expiryTime: localStorage.getItem('expiryTime'),
    });

    const { clearUserData } = useUserData();
    const { clearDNATestTracker } = useDNATestTracker()
    const { clearExperts } = useExpert()
    const { clearSurveyData } = useSurveyData()
    const { clearConsults } = useConsults()
    const { clearBloodTestTracker } = useBloodTestTracker()
    const { clearHealthPlan } = useHealthPlan()
    const { clearTutorial } = useTutorial()
    const { clearProducts } = useProducts()

    useEffect(() => {
        if (auth.idToken) localStorage.setItem('idToken', auth.idToken);
        else localStorage.removeItem('idToken');

        if (auth.userId) localStorage.setItem('userId', auth.userId);
        else localStorage.removeItem('userId');

        if (auth.accessToken) localStorage.setItem('accessToken', auth.accessToken);
        else localStorage.removeItem('accessToken');

        if (auth.refreshToken) localStorage.setItem('refreshToken', auth.refreshToken);
        else localStorage.removeItem('refreshToken');

        if (auth.expiryTime) localStorage.setItem('expiryTime', auth.expiryTime.toString());
        else localStorage.removeItem('expiryTime');
    }, [auth]);

    // Function to log in
    const login = (idToken: string, userId: string, accessToken: string, refreshToken: string, expiryIn: number) => {
        const expiryTime = Date.now() + expiryIn * 1000;

        // Convert to a human-readable format
        const expiryDate = new Date(expiryTime);
        const formattedExpiry = `${expiryDate.getHours().toString().padStart(2, "0")}:${expiryDate
            .getMinutes()
            .toString()
            .padStart(2, "0")} - ${expiryDate.getDate().toString().padStart(2, "0")}/${(expiryDate.getMonth() + 1)
                .toString()
                .padStart(2, "0")}/${expiryDate.getFullYear()}`;
        setAuth({ idToken, userId, accessToken, refreshToken, expiryTime: formattedExpiry });
    };

    // Function to log out
    const logout = () => {
        setAuth({ idToken: null, userId: null, accessToken: null, refreshToken: null, expiryTime: null });
        clearUserData()
        clearDNATestTracker()
        clearExperts()
        clearSurveyData()
        clearConsults()
        clearBloodTestTracker()
        clearHealthPlan()
        clearTutorial()
        clearProducts()
        navigate("/")
    };

    return (
        <AuthContext.Provider value={{ auth, login, logout }}>
            {children}
        </AuthContext.Provider>
    );
};

// Custom hook to use the authentication context
export const useAuth = (): AuthContextType => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error('useAuth must be used within an AuthProvider');
    }
    return context;
};
