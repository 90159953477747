import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import BackButton from "./../components/BackButton"

const MealPlan = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()

    const folderConfig = [
        {
            category: MealPlanCategory.Diet,
            name: "Dieta",
            emoji: "🥙",
            eventType: EventType.DietFolderClicked,
        },
        {
            category: MealPlanCategory.ShoppingList,
            name: "Liste della spesa",
            emoji: "🛒",
            eventType: EventType.ShoppingListFolderClicked,
        },
        {
            category: MealPlanCategory.TipsAndRecepes,
            name: "Tips e ricette",
            emoji: "🧑‍🍳",
            eventType: EventType.TipsAndRecepesFolderClicked,
        },
        {
            category: MealPlanCategory.EatingHabits,
            name: "Eating habits",
            emoji: "🍽",
            eventType: EventType.EatingHabitsFolderClicked,
        },
        {
            category: MealPlanCategory.SeasonalFruitsAndVegetables,
            name: "Frutta e verdura di stagione",
            emoji: "🍎",
            eventType: EventType.SeasonalFruitsAndVegetablesFolderClicked,
        },
    ];

    const handleFolderClick = (category: MealPlanCategory, eventType: EventType) => {
        navigate(category);
        email &&
            idToken &&
            saveEvent(
                email,
                eventType,
                (success) => console.log(success),
                (error) => console.log(error),
                "meal_plan_page"
            );
    };

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "meal_plan_page"
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Health Plan"
                    onClick={() => navigate("/health-plan")}
                />
                <div className="font-bold text-[25px] leading-[33px]">
                    Meal Plan
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4 md:grid-cols-3">
                {folderConfig.map(({ category, name, emoji, eventType }) => (
                    <MealPlanCard
                        key={category}
                        name={name}
                        emoji={emoji}
                        onClick={() => handleFolderClick(category, eventType)}
                    />
                ))}
            </div>
        </div>
    )
}

export default MealPlan

enum MealPlanCategory {
    Diet = "diet",
    ShoppingList = "shopping-list",
    TipsAndRecepes = "tips-and-recepes",
    EatingHabits = "eating-habits",
    SeasonalFruitsAndVegetables = "seasonal-fruits-and-vegetables",
}

interface Props {
    name: string,
    emoji: string,
    onClick: () => void,
}

const MealPlanCard: React.FC<Props> = ({
    name,
    emoji,
    onClick,
}) => {

    // ********************************************************************************

    return (
        <div
            className="w-full aspect-square rounded-[15px] shadow-platform flex items-center justify-center cursor-pointer md:h-[180px]"
            onClick={onClick}
        >
            <div className="w-[80%] h-[80%] flex flex-col justify-between">
                <div className="font-bold text-[12px] leading-[20px]">
                    {name}
                </div>
                <div className="w-[35px] h-[35px] flex items-center justify-center text-[35px] leading-[35px] self-end md:w-[80px] md:h-[80px] md:text-[80px]">
                    {emoji}
                </div>
            </div>
        </div>
    )
}
