import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import { updateBloodTrackerByUserId } from "../../../../utils/apis/dna-test-tracker";
import { saveEvent } from "../../../../utils/apis/user-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { useBloodTestTracker } from "../../../../utils/context/BloodTextTrackerContext";
import { useUserData } from "../../../../utils/context/UserDataContext";
import { EventType } from "../../../../utils/enums/EventType";
import { BloodTestTrackingStatus } from "../../../../utils/enums/TrackingStatus";
import CloseButton from "../../components/CloseButton";

const BloodTutorial = () => {
  const { auth } = useAuth()
  const userId = auth.userId
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const { updateBloodTestTracker } = useBloodTestTracker()
  const navigate = useNavigate()
  const [index, setIndex] = useState(1)
  const tutorialContentRef = useRef<null | HTMLDivElement>(null);

  const [isLoading, setIsLoading] = useState(false)

  const onBackClick = () => {
    setIndex((prev) => prev - 1)
  }

  const onConfirmClick = () => {
    if (!userId || !idToken) {
      return
    }
    if (!email || !idToken) return
    if (index === 10) {
      setIsLoading(true)
      updateBloodTrackerByUserId(
        userId,
        idToken,
        BloodTestTrackingStatus.TestDone,
        (tracker) => {
          setIsLoading(false)
          const newStatus = tracker.tracking_status
          updateBloodTestTracker(newStatus)
          navigate("/diagnostica/tests-and-reports/blood-test")
        },
        (error) => {
          setIsLoading(false)
          console.log(error)
        },
      )
      saveEvent(
        email,
        EventType.BloodTutorialDoneButtonClicked,
        () => { },
        () => { },
        "blood_tutorial_page",
      )
    } else {
      setIndex((prev) => prev + 1)
      saveEvent(
        email,
        EventType.BloodTutorialForwardButtonClicked,
        (success) => { console.log(success) },
        (error) => { console.log(error) },
        "blood_tutorial_page",
      )
    }
  }

  const [showAllComponentsDialog, setShowAllComponentsDialog] = useState(false)
  const [showGarzeCerottiDialog, setShowGarzeCerottiDialog] = useState(false)
  const [showLancetteDialog, setShowLancetteDialog] = useState(false)
  const [showCollectingPaperDialog, setShowCollectingPaperDialog] = useState(false)
  const [showPlasticBagDialog, setShowPlasticBagDialog] = useState(false)

  // Preload images
  const preloadImages = (imagePaths: string[]) => {
    imagePaths.forEach((path) => {
      const img = new Image();
      img.src = path;
    });
  };

  // Effect to preload images on component mount
  useEffect(() => {
    const imagesToPreload = [
      "/images/new-platform/components/blood-test-all-components.jpg",
      "/images/new-platform/components/blood-test-garze-cerotti.jpg",
      "/images/new-platform/components/blood-test-lancette.jpg",
      "/images/new-platform/components/blood-test-collecting-paper.jpg",
      "/images/new-platform/components/blood-test-plastic-bag.jpg",
    ];

    preloadImages(imagesToPreload);

    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "blood_tutorial_page",
    )
  }, []);

  // Scroll to top when index changes
  useEffect(() => {
    if (tutorialContentRef.current) {
      tutorialContentRef.current.scrollIntoView({ behavior: "smooth" }); // Scroll the content into view
    }
  }, [index]);

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col">
          <div ref={tutorialContentRef} className="w-full h-full flex flex-col">
            <div className="w-full flex items-center justify-between my-[10px]">
              <div className="font-semibold text-[12px] leading-[17px]"><span className="text-holifya-blue">{index}</span> /10</div>
              <CloseButton
                onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
              />
            </div>
            {
              index === 1 &&
              <div>
                <div className="font-bold text-[25px] leading-[35px] mb-[20px]">
                  Effettua l'autoprelievo per le analisi degli Aminoacidi
                </div>
                <div className="font-bold text-[16px] leading-[22px] mb-[10px]">
                  Prima di iniziare
                </div>
                <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                  Leggi attentamente il manuale di istruzioni prima di eseguire il test.<br />
                  Prenditi circa 30 minuti per prepararti ed eseguire il test.
                </div>
                <div className="w-full  rounded-[15px] bg-[#F7EBAF]">
                  <div className="w-full p-[20px]">
                    <div className="font-bold text-[20px] leading-[26px] mb-[10px]">⚠️ Attenzione</div>
                    <div className="font-normal text-[16px] leading-[26px]">
                      Per la raccolta del sangue, bisogna <strong>essere a digiuno</strong>.
                      Questo significa che non bisogna avere bevuto o mangiato cibi zuccherati per circa 8 ore prima del test.
                      Bere acqua è comunque consigliato in quanto può rendere la raccolta del sangue più facile.
                    </div>
                  </div>
                </div>
              </div>
            }
            {
              index === 2 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px] mb-[10px]">
                  Non eseguire il test...
                </div>
                <ul className="list-disc ml-[20px]">
                  <li className="ml-[20px]">Subito dopo un eccessivo sforzo fisico.</li>
                  <li className="ml-[20px]">Se ti senti a disagio alla vista del sangue. In questo caso, si raccomanda di eseguire la raccolta del sangue in presenza di un'altra persona.</li>
                </ul>
                <div className="font-bold text-[16px] leading-[23px] mt-[30px] mb-[10px]">
                  Consulta il tuo medico prima dell'uso se...
                </div>
                <ul className="list-disc ml-[20px]">
                  <li className="ml-[20px]">Hai una tendenza al sanguinamento o un disturbo della coagulazione o hai recentemente assunto anticoagulanti ("fluidificanti del sangue").</li>
                  <li className="ml-[20px]">Sei incinta o allatti, poiché i valori di riferimento possono differire in questi casi.</li>
                </ul>
                <div className="font-bold text-[16px] leading-[23px] mt-[30px] mb-[10px]">
                  Avviso sull'età
                </div>
                <div className="mb-[20px]">
                  Il nostro test è destinato a persone di età superiore ai 18 anni. I minori dovrebbero eseguire il test solo sotto la supervisione di un tutore legale.
                  Mantieni il kit di test fuori dalla portata dei bambini.
                </div>
              </div>
            }
            {
              index === 3 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px] mb-[10px]">
                  Avvertenze
                </div>
                <ul className="list-disc ml-[20px]">
                  <li className="ml-[20px]">
                    Il test e tutto il suo contenuto è per uso singolo ed individuale. Il campione di sangue deve provenire dalla persona per cui il test è inteso. Prestare particolare attenzione all'igiene e raccomandarsi di eseguire il prelievo in un posto pulito.
                  </li>
                  <li className="ml-[20px]">
                    Non utilizzare le lancette per scopi diversi da quelli descritti e non riutilizzarli.
                  </li>
                  <li className="ml-[20px]">
                    Non utilizzare lancette all'interno del kit che non avevano il tappo protettivo.
                  </li>
                  <li className="ml-[20px]">
                    Conservare i componenti del test a temperature tra 4°C e 30°C e non utilizzare il kit dopo la data di scadenza.
                  </li>
                  <li className="ml-[20px]">
                    Smaltire tutti i materiali usati secondo le norme locali di smaltimento dei rifiuti o secondo le indicazioni del produttore.
                  </li>
                  <li className="ml-[20px]">
                    Il sangue deve essere raccolto solo dalle dita seguendo le istruzioni di seguito.
                  </li>
                  <li className="ml-[20px]">
                    Contatta il supporto al <strong>333 2022 743</strong> o allindirizzo mail <strong>support@holifya.com</strong> se ci sono problemi durante la procedura.
                  </li>
                </ul>
              </div>
            }
            {
              index === 4 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[25px] leading-[30px] mb-[10px]">
                  Cominciamo!
                </div>
                <div className="font-bold text-[16px] leading-[23px] mb-[10px]">
                  Controlla il kit
                </div>
                <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                  <span className="font-bold text-holifya-blue underline cursor-pointer" onClick={() => setShowAllComponentsDialog(true)}>Controlla che tutti i componenti siano presenti</span> (puoi verificarlo anche tramite il libretto di
                  istruzioni cartaceo presente nel kit). Se il kit o l'imballaggio è danneggiato, contatta il nostro supporto
                  al <strong>333 2022 743</strong> o all'indirizzo mail <strong>support@holifya.com.</strong><br />
                  <strong>Nota</strong>: Un imballaggio aperto o danneggiato può influenzare la sterilità dei componenti del kit.
                </div>
                <div className="font-bold text-[16px] leading-[23px] mb-[10px]">
                  Preparazione
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  Apri il sacchetto del campione. <strong>Apri le confezioni</strong> contenenti le <span className="font-bold text-holifya-blue underline cursor-pointer" onClick={() => setShowGarzeCerottiDialog(true)}>garze sterili, le garze disinfettanti e i cerotti</span> e <strong>tirali leggermente fuori</strong> senza disimballarli completamente.
                </div>
                <div className="mt-[20px]">
                  <strong>Rimuovi il cappuccio protettivo</strong> da una delle <span className="font-bold text-holifya-blue underline cursor-pointer" onClick={() => setShowLancetteDialog(true)}>lancette</span> e posiziona la carta per la raccolta del sangue su una superficie pulita.
                </div>
              </div>
            }
            {
              index === 5 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px] mb-[10px]">
                  Scegli il dito
                </div>
                <div>
                  Scegli un dito per la raccolta del sangue basandoti sulle seguenti regole:
                </div>
                <ul className="list-disc ml-[20px]">
                  <li className="ml-[20px] my-[5px]">
                    Se sei destrorso, usa la mano sinistra; se sei mancino, usa la mano destra.
                  </li>
                  <li className="ml-[20px] my-[5px]">
                    Usa il dito medio o l'anulare. Il punto di raccolta dovrebbe essere a circa 3 millimetri dal centro della punta del dito verso l'esterno.
                  </li>
                </ul>
                <div className="w-full h-[195px] rounded-[15px] border-[3px] border-solid border-[#416AB3] mt-[20px] mb-[15px] flex items-center justify-center">
                  <img src="/images/new-platform/components/blood-test-fingers.svg" />
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong> Usare la mano opposta a quella dominante riduce il rischio di infezione. Lo stesso vale per le dita: usiamo l'anulare e il medio che sono significativamente meno utilizzati nella vita quotidiana rispetto all'indice e al pollice. Sconsigliamo di utilizzare il mignolo perché la pelle molto sottile può rendere la puntura più dolorosa.
                </div>
              </div>
            }
            {
              index === 6 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px]">Stimola la circolazione del sangue</div>
                <div className="font-normal text-[16px] leading-[26px] mt-[10px]">
                  Per ottenere un campione sufficiente, una buona circolazione del sangue è molto importante.
                </div>
                <ul className="list-disc ml-[20px]">
                  <li className="ml-[20px] my-[5px]">
                    Esegui un esercizio che stimoli la circolazione, ad esempio, squat o rotazioni delle braccia, per alcuni minuti.
                  </li>
                  <li className="ml-[20px] my-[5px]">
                    Lava poi accuratamente le mani con sapone sotto acqua calda per almeno 20 secondi e asciugale con un asciugamano pulito. Il calore stimolerà il flusso sanguigno verso le dita.
                  </li>
                  <li className="ml-[20px] my-[5px]">
                    Se le mani sono ancora fredde, sfregale velocemente insieme per un minuto e massaggia il dito scelto.
                  </li>
                </ul>
                <div className="w-full h-[193px] rounded-[15px] border-[3px] border-solid border-[#416AB3] my-[15px] flex items-center justify-center">
                  <img src="/images/new-platform/components/blood-test-wash-hands.svg" />
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong> Questi passaggi non devono essere trascurati, poiché una preparazione accurata per migliorare il flusso sanguigno è essenziale per una raccolta di successo.
                </div>
              </div>
            }
            {
              index === 7 &&
              <div className="mt-[10px]">
                <div className="font-normal text-[16px] leading-[23px]"><strong>Raccolta del sangue</strong> (1/2)</div>
                <ul className="list-disc ml-[20px]">
                  <li className="my-[5px]">
                    <strong>Disinfetta accuratamente</strong> la punta del dito con la <span className="font-bold underline text-holifya-blue" onClick={() => setShowGarzeCerottiDialog(true)}>garza disinfettante</span>.
                  </li>
                  <li className="my-[5px]">
                    Attendi circa 30 secondi fino a che il dito non sia asciutto.
                  </li>
                  <li className="my-[5px]">
                    Ora puoi usare la <span className="font-bold underline text-holifya-blue" onClick={() => setShowLancetteDialog(true)}>lancetta</span>. Posiziona il dito su una superficie ferma (ad esempio, il piano del tavolo) e <strong>premi la lancetta fermamente contro il dito</strong> leggermente di lato rispetto al centro. Dovresti sentire un clic e sentire un leggero pizzicore.
                  </li>
                  <li className="my-[5px]">
                    Se si forma una goccia di sangue dopo la puntura, procedi al passo successivo. Se non c'è sangue, massaggia delicatamente il dito.
                  </li>
                  <li>
                    <strong>Rimuovi la prima goccia</strong> di sangue con la <span className="font-bold underline text-holifya-blue" onClick={() => setShowGarzeCerottiDialog(true)}>garza sterile</span>.
                  </li>
                </ul>
                <div className="w-full h-[193px] rounded-[15px] border-[3px] border-solid border-[#416AB3] my-[15px] flex items-center justify-center">
                  <img src="/images/new-platform/components/blood-test-hands-step.svg" />
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong> La prima goccia di sangue serve a lavare via eventuali residui di pelle e cellule, che possono influenzare negativamente l'analisi in laboratorio.
                </div>
              </div>
            }
            {
              index === 8 &&
              <div className="mt-[10px]">
                <div className="font-normal text-[16px] leading-[23px]"><strong>Raccolta del sangue</strong> (2/2)</div>
                <ul className="list-disc ml-[20px]">
                  <li className="my-[5px]">
                    Ora, avvicinando il dito alla carta, lascia <strong>cadere il sangue dal dito sulla</strong> <span className="font-bold text-holifya-blue underline" onClick={() => setShowCollectingPaperDialog(true)}>carta per la raccolta</span> del sangue. Importante: assicurati che solo la goccia di sangue tocchi la carta, non il dito.
                  </li>
                  <li className="my-[5px]">
                    <strong>Completa</strong> la raccolta <strong>senza interruzioni</strong> e non aggiungere sangue fresco a gocce che si sono già asciugate.
                  </li>
                </ul>
                <div className="font-bold text-[16px] leading-[23px] mt-[10px]">Applicazioni errate</div>
                <div className="w-full h-[193px] rounded-[15px] border-[3px] border-solid border-[#416AB3] my-[15px] flex items-center justify-center">
                  <img src="/images/new-platform/components/blood-test-sample-collection.svg" />
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong>  Ripeti questi passaggi (utilizzando un dito diverso se necessario) fino a riempire almeno 2 cerchi. Non preoccuparti se la raccolta non è sempre “perfetta”. è più importante che la card sia completamente saturata e che la macchia sia visibile anche sul retro. Tuttavia, evita “troppo poco” o di “applicare due volte”.
                </div>
              </div>
            }
            {
              index === 9 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px]">Cura la ferita</div>
                <ul className="list-disc ml-[20px]">
                  <li className="my-[5px]">
                    Copri l'area della puntura sul tuo dito con una <span className="font-bold text-holifya-blue underline" onClick={() => setShowGarzeCerottiDialog(true)}>garza sterile</span> e applica una <strong>leggera pressione</strong> fino a quando il <strong>sanguinamento</strong> non si <strong>ferma</strong>.
                  </li>
                  <li className="my-[5px]">
                    <strong>Applica il <span className="font-bold text-holifya-blue underline" onClick={() => setShowGarzeCerottiDialog(true)}>cerotto</span></strong> sul sito di puntura per proteggerlo da contaminazioni.
                  </li>
                </ul>
                <div className="w-full h-[193px] rounded-[15px] border-[3px] border-solid border-[#416AB3] my-[15px] flex items-center justify-center">
                  <img src="/images/new-platform/components/blood-test-patch.svg" />
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong> Coprire la ferita protegge sia te che l'ambiente attorno a te.
                </div>
              </div>
            }
            {
              index === 10 &&
              <div className="mt-[10px]">
                <div className="font-bold text-[16px] leading-[23px]">Lascia asciugare la carta</div>
                <ul className="list-disc ml-[20px]">
                  <li className="my-[5px]">
                    Lascia asciugare la <span className="font-bold text-holifya-blue underline" onClick={() => setShowCollectingPaperDialog(true)}>carta di raccolta del sangue</span> per <strong>almeno 2 ore</strong> e fino a 12 ore a temperatura ambiente in un luogo pulito e ben protetto dalla luce diretta del sole.
                  </li>
                  <li className="my-[5px]">
                    Dopo l'asciugatura, metti la carta di raccolta dentro <span className="font-bold text-holifya-blue underline" onClick={() => setShowPlasticBagDialog(true)}>il sacchetto di plastica insieme al dessiccatore</span>.
                  </li>
                </ul>
                <div className="w-full rounded-[15px] bg-[#F7EBAF] my-[15px]" >
                  <div className="w-full p-[20px]">
                    <div className="font-bold text-[20px] leading-[26px] mb-[15px]">⚠️ Attenzione</div>
                    <div className="font-normal text-[16px] leading-[26px]">Non asciugare la carta su un calorifero o altre fonti di calore. Questo renderebbe il campione di sangue non analizzabile.</div>
                  </div>
                </div>
                <div className="font-normal text-[16px] leading-[26px]">
                  <strong>Nota:</strong> Il dessiccatore mantiene un livello costante di umidità all'interno del sacchetto di plastica, il che è importante per la stabilità del campione e per ulteriori analisi di laboratorio
                </div>
              </div>
            }
            <div className="w-full h-[50px] mt-[20px] flex-shrink-0">
              <PrimaryButton
                text={index !== 10 ? "Avanti" : "Fatto, Avanti"}
                pointer={true}
                disabled={false}
                fontSize={16}
                onClick={onConfirmClick}
                isLoading={isLoading}
              />
            </div>
            {
              index !== 1 &&
              <div className="w-full h-[40px] mt-[10px] flex-shrink-0">
                <WhiteButton
                  text="Indietro"
                  onClick={onBackClick}
                  uppercase={false}
                  fontSize={16}
                />
              </div>
            }
            <div className="p-[10px]" />
          </div>
      {showAllComponentsDialog && <AllComponentsDialog onCloseClick={() => setShowAllComponentsDialog(false)} />}
      {showGarzeCerottiDialog && <GarzeCerottiDialog onCloseClick={() => setShowGarzeCerottiDialog(false)} />}
      {showLancetteDialog && <LancetteDialog onCloseClick={() => setShowLancetteDialog(false)} />}
      {showCollectingPaperDialog && <CollectingPaperDialog onCloseClick={() => setShowCollectingPaperDialog(false)} />}
      {showPlasticBagDialog && <PlasticBagDialog onCloseClick={() => setShowPlasticBagDialog(false)} />}
    </div>
  )
}

export default BloodTutorial

interface AllComponentsProps {
  onCloseClick: () => void,
}

const AllComponentsDialog: React.FC<AllComponentsProps> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Tutti i componenti del kit:
        </div>
        <ul className="list-disc ml-[20px]">
          <li className="my-[5px]">2x garze disinfettanti</li>
          <li className="my-[5px]">1x manuale di istruzioni</li>
          <li className="my-[5px]">1x carta per la raccolta del sangue</li>
          <li className="my-[5px]">2x garze sterili</li>
          <li className="my-[5px]">2x cerotti</li>
          <li className="my-[5px]">2x lancette/pungidito sterili</li>
          <li className="my-[5px]">1x busta per l'invio</li>
          <li className="my-[5px]">1x sacchetto di plastica richiudibile con dessiccante per controllo umidità</li>
          <li className="my-[5px]">1x Member card</li>
          <li className="my-[5px]">1x lettera di vettura per il ritiro</li>
        </ul>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-all-components.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

interface GarzeCerottiProps {
  onCloseClick: () => void,
}

const GarzeCerottiDialog: React.FC<GarzeCerottiProps> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Garze sterili, garze disinfettanti e
          cerotti
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Garze disinfettanti<br />
          B - Garze sterili<br />
          C - Cerotti<br />
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-garze-cerotti.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

interface Props3 {
  onCloseClick: () => void,
}

const LancetteDialog: React.FC<Props3> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Lancette
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Lancette
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-lancette.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

const CollectingPaperDialog: React.FC<Props3> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Carta per la raccolta del sangue
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Carta per la raccolta del sangue
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-collecting-paper.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}

const PlasticBagDialog: React.FC<Props3> = ({
  onCloseClick,
}) => {
  return (
    <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
      <div className="w-full p-[20px]">
        <div className="font-bold text-[20px] leading-[25px] mb-[10px]">
          Sacchetto di plastica richiudibile
        </div>
        <div className="font-normal text-[16px] leading-[26px] mb-[10px]">
          A - Sacchetto di plastica richiudibile con dessiccante per controllo umidità
        </div>
        <div className="w-full h-[169px] rounded-[15px] border-[3px] border-solid border-[#416AB3]">
          <img src="/images/new-platform/components/blood-test-plastic-bag.jpg" />
        </div>
        <div className="w-full h-[40px] mt-[20px]">
          <WhiteButton
            text="Chiudi"
            onClick={onCloseClick}
            uppercase={false}
          />
        </div>
      </div>
    </div>
  )
}
