import { BloodTestTracker } from "../api-objects/BloodTestTracker";
import { DnaTestTracker } from "../api-objects/DnaTestTracker";
import { BASE_URL, requestOptionsGET } from "../Constants";
import { BloodTestTrackingStatus, TrackingStatus } from "../enums/TrackingStatus";
import { ErrorWithMessageCallback, SuccessWithMessageCallback, SuccessWithTrackerCallback, UpdateTrackerByOrderIdSuccessCallback } from "./callback-helpers";

export async function getTrackerByUserId(
    userId: string,
    idToken: string,
): Promise<DnaTestTracker[]> {
    try {
        const response = await fetch(`${BASE_URL}/api/track/${userId}`, requestOptionsGET(idToken));
        if (response.ok) {
            const trackers: DnaTestTracker[] = await response.json();
            return trackers;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else {
            throw new Error(response.statusText || "Failed to fetch trackers");
        }
    } catch (error) {
        console.error("Get tracker failed:", error);
        throw error instanceof Error ? error : new Error("An unexpected error occurred");
    }
}

export async function updateTrackerByOrderId(
    orderId: string,
    idToken: string,
    status: TrackingStatus,
    onSuccess: UpdateTrackerByOrderIdSuccessCallback,
    onError: ErrorWithMessageCallback,
    handleSessionExpiry?: () => void,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/track/order/${orderId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tracking_status: status,
                }
            )
        })
        if (response.ok) {
            const tracker: DnaTestTracker = await response.json()
            onSuccess(tracker)
        } else if (response.status === 401 && handleSessionExpiry) {
            handleSessionExpiry();
        } else {
            const errorMessage = response.statusText || "Failure";
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Update tracker failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function createBloodTestTrackItem(
    user_id: string,
    id_token: string,
    order_id: string,
    tracking_status: BloodTestTrackingStatus,
    onSuccess: SuccessWithMessageCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/blood-track`, {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': id_token,
            },
            body: JSON.stringify({
                user_id,
                order_id,
                tracking_status,
            }),
        })
        if (response.ok) {
            const message: string = await response.json()
            onSuccess(message)
        } else {
            onError("errore")
        }
    } catch (error) {
        console.error("Create blood test tracker failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}

export async function getBloodTrackerByUserId(
    userId: string,
    idToken: string,
): Promise<BloodTestTracker> {
    try {
        const response = await fetch(`${BASE_URL}/api/blood-track/${userId}`, requestOptionsGET(idToken));

        if (response.ok) {
            const tracker: BloodTestTracker = await response.json();
            return tracker;
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else {
            const errorMessage = response.statusText || "Failed to fetch blood tracker";
            throw new Error(errorMessage);
        }
    } catch (error) {
        console.error("Get blood tracker failed:", error);
        if (error instanceof Error) {
            throw error;
        } else {
            throw new Error("An unexpected error occurred");
        }
    }
}


export async function updateBloodTrackerByUserId(
    userId: string,
    idToken: string,
    status: BloodTestTrackingStatus,
    onSuccess: SuccessWithTrackerCallback,
    onError: ErrorWithMessageCallback,
) {
    try {
        const response = await fetch(`${BASE_URL}/api/blood-track/user_id/${userId}`, {
            method: 'PATCH',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': idToken,
            },
            body: JSON.stringify(
                {
                    tracking_status: status,
                }
            )
        })
        if (response.ok) {
            const tracker: BloodTestTracker = await response.json()
            onSuccess(tracker)
        } else {
            const errorMessage = response.statusText || "Failure";
            onError(errorMessage);
        }
    } catch (error) {
        console.error("Get blood tracker failed:", error);
        return { success: false, error: "An unexpected error occurred" };
    }
}