import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../../utils/api-objects/Exam"
import { getAllMealPlanExamsByType } from "../../../../utils/apis/emr-service"
import { saveEvent } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../../utils/enums/BucketFolder"
import { EventType } from "../../../../utils/enums/EventType"
import BackButton from "./../../components/BackButton"
import { PdfCard, PdfType } from "./../../components/PdfCard"

const ShoppingList = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const userId = auth.userId
  const { userData } = useUserData()
  const email = userData.email
  const navigate = useNavigate()

  const [report, setReport] = useState<Exam>()
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    if (!userId || !idToken) {
      return
    }
    getAllMealPlanExamsByType(
      userId,
      idToken,
      BucketFolder.ShoppingList,
      (exams) => {
        setReport(exams[0])
        setIsLoading(false)
      },
      (error) => {
        console.error(error)
        setIsLoading(false)
      }
    );
    if (!email) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "shopping_list_page_page"
    )
  }, []);

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col space-y-[20px]">
          <div className="mt-[10px] space-y-[10px]">
            <BackButton
              text="Torna a Meal Plan"
              onClick={() => navigate("/health-plan/meal-plan")}
            />
          <div className="font-bold text-[25px] leading-[33px]">
            Lista della spesa
          </div>
          </div>
          {
            report ?
              <PdfCard exam={report} pdfType={PdfType.ShoppingList} /> :
              <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                La tua lista della spesa sarà disponibile dopo il tuo consulto con nutrizionista.
              </div>
          }
          <div className="p-[10px]" />
    </div>
  )
}

export default ShoppingList
