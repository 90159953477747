import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';

interface SurveyDataState {
    healthAssessmentQuestions: string[] | null,
    checkupSurveyQuestions: string[] | null,
    healthAssessmentCompleted: boolean | null;
}

// Define the shape of the survey context
interface SurveyDataContextType {
    surveyData: SurveyDataState;
    updateHealthAssessmentQuestions: (ids: string[]) => void;
    updateCheckupSurveyQuestions: (ids: string[]) => void;
    setHealthAssessmentCompleted: (completed: boolean) => void;
    clearSurveyData: () => void;
}

// Create the survey context with default values
const SurveyDataContext = createContext<SurveyDataContextType | undefined>(undefined);

// Create a provider component
export const SurveyDataProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    // Initialize state with data from localStorage
    const [surveyData, setSurveyData] = useState<SurveyDataState>({
        healthAssessmentQuestions: JSON.parse(localStorage.getItem('healthAssessmentQuestions') || 'null'),
        checkupSurveyQuestions: JSON.parse(localStorage.getItem('checkupSurveyQuestions') || 'null'),
        healthAssessmentCompleted: JSON.parse(localStorage.getItem('healthAssessmentCompleted') || 'false'),
    });

    // Effect to update localStorage when survey state changes
    useEffect(() => {
        if (surveyData.healthAssessmentQuestions) {
            localStorage.setItem('healthAssessmentQuestions', JSON.stringify(surveyData.healthAssessmentQuestions));
        } else {
            localStorage.removeItem('healthAssessmentQuestions');
        }

        if (surveyData.checkupSurveyQuestions) {
            localStorage.setItem('checkupSurveyQuestions', JSON.stringify(surveyData.checkupSurveyQuestions));
        } else {
            localStorage.removeItem('checkupSurveyQuestions');
        }

        localStorage.setItem('healthAssessmentCompleted', JSON.stringify(surveyData.healthAssessmentCompleted));
    }, [surveyData]);

    // Function to update health assessment questions
    const updateHealthAssessmentQuestions = (healthAssessmentQuestions: string[]) => {
        setSurveyData(prevState => ({ ...prevState, healthAssessmentQuestions }));
    };

    // Function to update check up survey questions
    const updateCheckupSurveyQuestions = (checkupSurveyQuestions: string[]) => {
        setSurveyData(prevState => ({ ...prevState, checkupSurveyQuestions }));
    };

    // Function to update health assessment completion status
    const setHealthAssessmentCompleted = (completed: boolean) => {
        setSurveyData(prevState => ({ ...prevState, healthAssessmentCompleted: completed }));
    };

    // Function to clear survey data
    const clearSurveyData = () => {
        setSurveyData({ healthAssessmentQuestions: null, checkupSurveyQuestions: null, healthAssessmentCompleted: false});
        localStorage.removeItem('healthAssessmentQuestions');
        localStorage.removeItem('checkupSurveyQuestions');
        localStorage.removeItem('healthAssessmentCompleted');
    };

    // Provide context value to children
    return (
        <SurveyDataContext.Provider
            value={{
                surveyData,
                updateHealthAssessmentQuestions,
                updateCheckupSurveyQuestions,
                setHealthAssessmentCompleted,
                clearSurveyData,
            }}
        >
            {children}
        </SurveyDataContext.Provider>
    );
};

// Custom hook to use the survey data context
export const useSurveyData = (): SurveyDataContextType => {
    const context = useContext(SurveyDataContext);
    if (context === undefined) {
        throw new Error('useUserData must be used within a SurveyProvider');
    }
    return context;
};
