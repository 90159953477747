import { useState } from "react"
import { useNavigate } from "react-router-dom"
import BackButton from "../../../components/BackButton"
import SecondaryButton from "../../../components/buttons/SecondaryButton"
import { loginApi } from "../../../utils/apis/auth-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { UnconfirmDirection, useRegistrationData } from "../../../utils/context/RegistrationContext"
import { LoadingOverlay } from "../../new-platform/components/Loaders"
import '../../styles.css'
import ChangePasswordDialog from "./components/ChangePasswordDialog"
import LoginBox from "./components/LoginBox"

const LoginPage = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData, saveData } = useRegistrationData()

    const [isLoading, setIsLoading] = useState(false)
    const [passwordErrorText, setPasswordErrorText] = useState("")
    const [showChangePasswordDialog, setShowChangePasswordDialog] = useState(false)

    const onBackButtonClick = () => {
        window.open("https://www.holifya.com/", "_self")
    }

    const onCreateAccountClick = () => {
        // if (registrationData && registrationData.registrationEmail && registrationData.password) {
        //     saveData(registrationData.registrationEmail, registrationData.password, UnconfirmDirection.LoginCreateButton)
        //     resendCodeApi(registrationData.registrationEmail,
        //         () => {
        //             console.log("mail mandata")
        //         },
        //         () => {
        //             console.log("Mail non mandata")
        //         });
        //     navigate("/email-registration-code")
        // } else {
            navigate("/email-registration")
        // }
    }

    const handleLoginInputsSubmit = (inputData: { [key: string]: string }) => {
        const email = inputData.email
        const password = inputData.password
        callLogin(email, password)
    }

    const callLogin = async (email: string, password: string) => {
        try {
            localStorage.clear()
            
            setIsLoading(true);
            setPasswordErrorText("");
            const result = await loginApi(email, password)
            if (result.unconfirmed) {
                saveData(email, password, UnconfirmDirection.LoginAttempt);
                navigate("/email-registration-code");
            } else if (result.passwordResetRequired) {
                setPasswordErrorText("Cambio password necessario");
                setShowChangePasswordDialog(true);
            } else {
                if (result.idToken && result.accessToken && result.userId && result.refreshToken && result.expiryTime) {
                    login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                    navigate("/fetch-user-data");
                } else {
                    throw new Error("Missing token or user information in the response.");
                }
            }
        } catch (error) {
            if (error instanceof Error) {
                setPasswordErrorText(error.message || "Indirizzo e-mail o password errata");
            } else {
                console.error("Unexpected error type:", error);
                setPasswordErrorText("An unexpected error occurred");
            }
        } finally {
            setIsLoading(false);
        }
    }

    // ******************************************************************************************************************

    return (
        <div className={`App relative`}>
            <div className={`w-[90%] h-[80px] flex justify-between items-center`}>
                <BackButton
                    onClick={onBackButtonClick}
                />
                <div className="w-[159px] h-[55px]">
                    <SecondaryButton
                        text="Crea un account"
                        onClick={onCreateAccountClick}
                        fontSize={12}
                    />
                </div>
            </div>
            <div className={`w-full h-[calc(100%-130px)] sm:h-[calc(100%-180px)] flex flex-col items-center justify-start sm:justify-center mt-[30px] sm:mt-[20px] overflow-y-auto scrollbar-hide`}>
                <div className={`w-[328px] p-[20px] flex bg-white`}>
                    <LoginBox
                        onSubmit={handleLoginInputsSubmit}
                        passwordError={passwordErrorText}
                    />
                </div>
            </div>
            {
                isLoading && <LoadingOverlay/>
            }
            {
                showChangePasswordDialog && <ChangePasswordDialog onClick={() => setShowChangePasswordDialog(false)} />
            }
        </div>
    )
}

export default LoginPage
