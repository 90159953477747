import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import { updateTrackerByOrderId } from "../../../../utils/apis/dna-test-tracker"
import { saveEvent } from "../../../../utils/apis/user-service"
import { YOUTUBE_VIDEO_TUTORIAL } from "../../../../utils/Constants"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useDNATestTracker } from "../../../../utils/context/DNATestTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import { TrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"

const Tutorial = () => {
    const { dnaTestTracker, updateDNATestTracker } = useDNATestTracker()
    const orderId = dnaTestTracker.orderId
    const status = dnaTestTracker.status
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const canProceed = orderId !== null && status !== null && idToken !== null

    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const onConfirmClick = () => {
        if (canProceed) {
            if (status === TrackingStatus.Delivered) {
                setIsLoading(true)
                updateTrackerByOrderId(
                    orderId,
                    idToken,
                    TrackingStatus.TestDone,
                    (tracker) => {
                        const newStatus = tracker.tracking_status
                        updateDNATestTracker(newStatus, orderId)
                        setIsLoading(false)
                        navigate("/diagnostica/tests-and-reports/dna-test/register-swab-code")
                    },
                    (error) => {
                        console.log(error)
                        setIsLoading(false)
                    },
                )
            } else {
                navigate("/diagnostica/tests-and-reports/dna-test/register-swab-code")
            }
        }
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.DnaTutorialDoneButtonClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_tutorial_page"
        )
    }

    const onWhiteButtonOneClick = () => {
        section2.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const onWhiteButtonTwoClick = () => {
        section1.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }

    const section1 = useRef<null | HTMLDivElement>(null);
    const section2 = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_tutorial_page",
        )
    }, [])

    return (
        <div className="w-full h-full flex flex-col flex-shrink-0 overflow-y-hidden">
            <div ref={section1} className="w-full h-full overflow-y-auto scrollbar-hide flex-shrink-0">
                <div className="w-full my-[10px] flex justify-end flex-shrink-0">
                    <CloseButton
                        onClick={() => navigate("/diagnostica/tests-and-reports/dna-test")}
                    />
                </div>
                <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                    Effettua l'autoprelievo per il test del DNA
                </div>
                <div className="font-normal text-[14px] leading-[20px] mt-[10px] mb-[20px] flex-shrink-0">
                    Puoi seguire le istruzioni cartacee presenti nel kit che hai ricevuto, seguire il video tutorial di autoprelievo o leggerle qui sotto.
                </div>
                <div className="font-bold text-[12px] leading-[17px] mt-[10px] mb-[20px] flex-shrink-0 md:mt-[30px]">
                    Video tutorial: Autoprelievo
                </div>
                <div className="w-full h-[214px] rounded-[15px] overflow-hidden relative flex-shrink-0 md:w-[832px] md:h-[492px] md:mb-[50px]">
                    <iframe
                        width="100%"
                        height="100%"
                        src={YOUTUBE_VIDEO_TUTORIAL}
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
                <div className="w-full h-[50px] mt-[20px] mb-[10px] self-center flex-shrink-0 md:w-[832px]">
                    <PrimaryButton
                        text="Fatto! Avanti"
                        pointer={true}
                        disabled={false}
                        onClick={onConfirmClick}
                        fontSize={16}
                        isLoading={isLoading}
                    />
                </div>
                <div className="w-full h-[50px] mt-[10px] mb-[20px] self-center flex-shrink-0 md:w-[832px]">
                    <WhiteButton
                        text="Leggi le istruzioni testuali"
                        onClick={onWhiteButtonOneClick}
                        fontSize={16}
                        uppercase={false}
                    />
                </div>
            </div>
            <div
                ref={section2}
                className="w-full h-full overflow-y-auto scrollbar-hide flex-shrink-0"
            >
                <div className="font-normal text-[16px] leading-[26px] mt-[10px] mb-[20px] flex-shrink-0">
                    Prima di effettuare il test assicurati che nei 60 minuti precedenti al prelievo tu non abbia:
                    mangiato, masticato gomme, fumato, lavato i denti, bevuto alcun tipo di liquido eccetto acqua.
                    Estrai lo swab dalla provetta tirando il tappo.
                </div>
                <div className="mt-[10px] mb-[20px]">
                    <div className="w-full h-[257px] mb-[10px]">
                        <img src="/images/new-platform/components/tutorial-1.svg" />
                    </div>
                    <div className="font-normal text-[16px] leading-[26px]">
                        <strong>1.</strong> Inserire lo swab nel cavo orale, porlo sulla lingua e inumidirlo di saliva.
                    </div>
                </div>
                <div className="mt-[10px] mb-[20px]">
                    <div className="w-full h-[257px] mb-[10px]">
                        <img src="/images/new-platform/components/tutorial-2.svg" />
                    </div>
                    <div className="font-normal text-[16px] leading-[26px]">
                        <strong>2.</strong> Chiudere la bocca e tenere lo swab in posizione per 10 secondi.
                    </div>
                </div>
                <div className="mt-[10px] mb-[20px]">
                    <div className="w-full h-[257px] mb-[10px]">
                        <img src="/images/new-platform/components/tutorial-3.svg" />
                    </div>
                    <div className="font-normal text-[16px] leading-[26px]">
                        <strong>3.</strong> Con l'indice e il medio, premere lo swab contro l'interno della guancia e strofinare energicamente 10 volte vicino alla parete gengivale.
                    </div>
                </div>
                <div className="mt-[10px] mb-[20px]">
                    <div className="w-full h-[257px] mb-[10px]">
                        <img src="/images/new-platform/components/tutorial-4.svg" />
                    </div>
                    <div className="font-normal text-[16px] leading-[26px]">
                        <strong>4.</strong>  Ripetere il passaggio sul lato opposto del cavo orale.
                    </div>
                </div>
                <div className="font-normal text-[16px] leading-[26px] my-[20px]">
                    Dopo il prelievo, reinserisci lo swab nella provetta e chiudi saldamente fino a
                    sentire un click.
                </div>
                <div className="w-full h-[120px] flex flex-col justify-between my-[30px]">
                    <div className="w-full h-[50px]">
                        <PrimaryButton
                            text="Fatto! Avanti"
                            pointer={true}
                            disabled={false}
                            onClick={onConfirmClick}
                            fontSize={16}
                            isLoading={isLoading}
                        />
                    </div>
                    <div className="w-full h-[50px]">
                        <WhiteButton
                            text="Guarda il tutorial"
                            onClick={onWhiteButtonTwoClick}
                            fontSize={16}
                            uppercase={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Tutorial
