import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../../components/buttons/PrimaryButton"
import WhiteButton from "../../../../components/buttons/WhiteButton"
import TextInputElement from "../../../../components/input/v2/TextInputElement"
import { updateBloodTrackerByUserId } from "../../../../utils/apis/dna-test-tracker"
import { saveEvent, updateUserInfoWithBloodcode } from "../../../../utils/apis/user-service"
import { useAuth } from "../../../../utils/context/AuthContext"
import { useBloodTestTracker } from "../../../../utils/context/BloodTextTrackerContext"
import { useUserData } from "../../../../utils/context/UserDataContext"
import { EventType } from "../../../../utils/enums/EventType"
import { BloodTestTrackingStatus } from "../../../../utils/enums/TrackingStatus"
import CloseButton from "../../components/CloseButton"

const RegisterSample = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const { updateBloodTestTracker } = useBloodTestTracker()

    const [bloodCode, setBloodCode] = useState("")
    const [errorText, setErrorText] = useState("")

    const [isLoading, setIsLoading] = useState(false)

    const isSubmitButtonDisabled = bloodCode.length < 9

    const onSubmitButtonClick = () => {
        if (!userId || !idToken) {
            return
        }

        setIsLoading(true)
        // assignBloodcode(
        //     bloodCode,
        //     userId,
        //     () => {
        //         updateTracker()
        //     },
        //     (error) => {
        //         console.log(error)
        //         setErrorText("Codice non valido")
        //         setIsLoading(false)
        //     },
        // )
        updateUserInfoWithBloodcode(
            userId,
            idToken,
            [bloodCode],
            () => {
                updateTracker()
            },
            (error) => {
                saveEvent(
                    email!,
                    `blood_code_input_error_first_${bloodCode}`,
                    (success) => { console.log(success) },
                    (error) => { console.log(error) },
                    "register_bloodcode_page"
                )
                console.log(error)
                updateUserInfoWithBloodcode(
                    userId,
                    idToken,
                    [bloodCode],
                    () => {
                        updateTracker()
                    },
                    (error) => {
                        saveEvent(
                            email!,
                            `blood_code_input_error_second_${bloodCode}`,
                            (success) => { console.log(success) },
                            (error) => { console.log(error) },
                            "register_bloodcode_page"
                        )
                        console.log(error)
                        setIsLoading(false)
                        setErrorText("Codice non valido")
                    },
                )
            },
        )
        if (!email) return
        saveEvent(
            email,
            EventType.BloodRegisterBloodcodeButtonClicked,
            () => { },
            () => { },
            "register_bloodcode_page",
        )
    }

    const updateTracker = () => {
        if (!userId || !idToken) {
            return
        }

        updateBloodTrackerByUserId(
            userId,
            idToken,
            BloodTestTrackingStatus.SampleRegistered,
            (tracker) => {
                setIsLoading(false)
                const newStatus = tracker.tracking_status
                updateBloodTestTracker(newStatus)
                navigate("/diagnostica/tests-and-reports/blood-test")
            },
            (error) => {
                console.log(error)
                setIsLoading(false)
            },
        )
    }

    const inputChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setBloodCode(e.target.value)
        setErrorText("")
    }

    const [showCodeExample, setShowCodeExample] = useState(false)

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "register_bloodcode_page",
        )
    })

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col">
            <div className="flex justify-end my-[10px]">
                <CloseButton
                    onClick={() => navigate("/diagnostica/tests-and-reports/blood-test")}
                />
            </div>
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                Registra campione
            </div>
            <div className="font-normal text-[16px] leading-[26px]">
                <span>Sulla Card per la raccolta del sangue è presente un Codice Analisi.</span>
                <span className="">
                    <img
                        className="ml-[5px] cursor-pointer inline-block mb-[3px]"
                        src="/images/new-platform/components/info-logo.svg"
                        alt="Info"
                        onClick={() => setShowCodeExample(true)}
                    />
                </span>
            </div>
            <div>
                È importante perché definisce la tua analisi e permette di anonimizzare il tuo campione ematico
            </div>
            <div className="font-normal text-[16px] leading-[26px] mt-[30px]">
                <strong>Inserisci il Codice Analisi</strong> qui sotto per registrare correttamente il tuo campione ematico.
            </div>
            <div className="mt-[30px]">
                <TextInputElement
                    label="Codice Analisi (sulla carta di raccolta)"
                    errorText={errorText}
                    inputValue={bloodCode}
                    onChange={inputChangeHandler}
                    placeholder={"Codice campione"}
                />
            </div>
            <div className="w-full h-[120px] flex flex-col justify-between mt-[20px]">
                <div className="w-full h-[50px]">
                    <PrimaryButton
                        text="Registra"
                        pointer={!isSubmitButtonDisabled}
                        disabled={isSubmitButtonDisabled}
                        onClick={onSubmitButtonClick}
                        isLoading={isLoading}
                    />
                </div>
                <div className="w-full h-[50px]">
                    <WhiteButton
                        text="Indietro"
                        onClick={() => navigate("/diagnostica/tests-and-reports/blood-test/tutorial")}
                        uppercase={false}
                    />
                </div>
            </div>
            {showCodeExample && <AllComponentsDialog onCloseClick={() => setShowCodeExample(false)} />}
        </div>
    )
}

export default RegisterSample

interface AllComponentsProps {
    onCloseClick: () => void,
}

const AllComponentsDialog: React.FC<AllComponentsProps> = ({
    onCloseClick,
}) => {
    return (
        <div className="w-[90%] rounded-[15px] bg-white shadow-platform absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            <div className="w-full p-[20px]">
                <div className="flex items-center justify-between mb-[20px]">
                    <div className="font-bold text-[20px] leading-[25px]">
                        Codice Analisi
                    </div>
                    <CloseButton
                        onClick={onCloseClick}
                    />
                </div>
                <img src="/images/new-platform/components/blood-test-codice-analisi.jpg" />
            </div>
        </div>
    )
}
