import { useState } from "react";
import { useNavigate } from "react-router-dom";
import BackButton from "../../../components/BackButton";
import { registerApi } from "../../../utils/apis/auth-service";
import { UnconfirmDirection, useRegistrationData } from "../../../utils/context/RegistrationContext";
import { LoadingOverlay } from "../../new-platform/components/Loaders";
import RegistrationBox from "./components/RegistrationBox";

const EmailRegistration = () => {

    const navigate = useNavigate();
    const { saveData } = useRegistrationData();

    const [emailError, setEmailError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const onBackButtonClick = () => {
        navigate("/login")
    }

    const handleInputsSubmit = (inputData: { [key: string]: string }, newsletterAccepted: boolean) => {
        const name = inputData.name
        const familyName = inputData.familyName
        const phoneNumber = inputData.phoneNumber
        const email = inputData.email
        const password = inputData.password
        callRegister(name, familyName, phoneNumber.replace(/\s+/g, ''), email, password, newsletterAccepted)
    }

    const callRegister = (name: string, familyName: string, phoneNumber: string, email: string, password: string, newsletterAccepted: boolean) => {
        setIsLoading(true)
        registerApi(
            email,
            password,
            name,
            familyName,
            phoneNumber,
            newsletterAccepted,
            (success) => {
                const message = success.message
                saveData(email, password, message === "User already exists but is unconfirmed. Please check your email to confirm registration." ? UnconfirmDirection.Registration : UnconfirmDirection.None)
                navigate("/email-registration-code")
                setIsLoading(false)
            },
            (error, showAlert) => {
                if (showAlert) {
                    alert("form error")
                } else {
                    setEmailError(true)
                }
                setIsLoading(false)
            }
        )
    }

    // ****************************************************************************************

    return (
        <div className="App">
            <div className={`w-full h-[80px] pl-[20px] md:pl-[5%] flex items-center`}>
                <BackButton onClick={onBackButtonClick} />
            </div>
            <div className={`w-full flex-1 items-center flex pt-[20px] mb-[20px] justify-center overflow-y-auto scrollbar-hide`}>
                <div className="w-full md:w-[368px] h-fit bg-white px-[20px] box-content">
                    <RegistrationBox
                        onSubmit={handleInputsSubmit}
                        emailError={emailError}
                    />
                </div>
            </div>
            {
                isLoading && <LoadingOverlay />
            }
        </div>
    )
}

export default EmailRegistration
