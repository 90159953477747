import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import SecondaryButton from "../../../../components/buttons/SecondaryButton";
import { Exam } from "../../../../utils/api-objects/Exam";
import { getAllExamsByType } from "../../../../utils/apis/emr-service";
import { BASE_URL, requestOptionsGET } from "../../../../utils/Constants";
import { useAuth } from "../../../../utils/context/AuthContext";
import { BucketFolder } from "../../../../utils/enums/BucketFolder";
import BackButton from "../../components/BackButton";
import LoadingSpinner from "../../components/Loaders";
import { PdfCard, PdfType } from "../../components/PdfCard";

const UploadedFromYou = () => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(false)

    const onUploadClick = () => {
        (document.querySelector(`.file-input-box-${PdfType.Uploads}`) as HTMLElement).click()
    };

    const [uploadedFile, setUploadedFile] = useState<File>()

    const [exams, setExams] = useState<Exam[]>()

    const onFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const files = (e.target as HTMLInputElement).files
        setUploadedFile(files![0])
    }

    const onPublishClick = async () => {
        if (!idToken || !uploadedFile) return
        setIsLoading(true)
        const filename = uploadedFile.name;
        const response = await fetch(`${BASE_URL}/api/health-plans/user/${userId}/upload-url?filename=${filename}&folder=from_user`, requestOptionsGET(idToken))
        if (response.ok) {
            const urlResponse = await response.json()
            console.log(urlResponse)
            const formData = new FormData()
            Object.entries(urlResponse.fields).forEach(([k, v]) => {
                formData.append(k, v as unknown as string)
            })
            formData.append('file', uploadedFile)
            const s3response = await axios.post(urlResponse.url, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
            setIsLoading(false)
            if (s3response.status === 204) {
                console.log("File uploaded successfully")
                setTriggerRefresh(true)
                setUploadedFile(undefined)
            } else {
                console.log("File not uploaded")
            }
            setIsLoading(false)
        } else {
            const responseJson = await response.json()
            console.log(responseJson)
            setIsLoading(false)
        }
    }

    const [triggerRefresh, setTriggerRefresh] = useState(false)

    useEffect(() => {
        if (!userId || !idToken) return
        getAllExamsByType(
            userId,
            idToken,
            BucketFolder.UploadedFromUser,
            (exams) => {
                setExams(exams)
            },
            (error) => { console.log(error) },
        )
    }, [triggerRefresh])

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Test e referti"
                    onClick={() => navigate("/diagnostica/tests-and-reports")}
                />
                <div className="md:flex md:justify-between md:items-center space-y-[10px] md:space-y-0">
                    <div className="flex flex-col md:flex-row md:justify-between">
                        <div className="font-bold text-[25px] leading-[33px]">
                            Caricati da te
                        </div>
                    </div>
                    <div className="space-y-[20px]">
                        <div className="w-full h-[50px]  md:w-[362px]">
                            <PrimaryButton
                                text="Seleziona"
                                pointer={true}
                                disabled={false}
                                onClick={onUploadClick}
                            />
                        </div>
                        {
                            uploadedFile &&
                            <div className="w-full h-[50px] mb-[20px] md:w-[362px]">
                                <SecondaryButton
                                    text="Carica"
                                    pointer={true}
                                    disabled={false}
                                    onClick={onPublishClick}
                                />
                            </div>
                        }
                    </div>
                </div>
                <input
                    type="file"
                    accept="application/pdf image/jpg image/jpeg"
                    className={`file-input-box-${PdfType.Uploads}`}
                    onChange={onFileInputChange}
                    hidden
                />
            </div>
            <div className="font-normal text-[16px] leading-[26px]">
                In questa cartella puoi accedere a tutti i documenti che hai condiviso con la tua Medical Equipe.
            </div>
            <div className="flex flex-wrap gap-4">
                {

                    exams && exams.length > 0 ? exams.map((exam, index) => (
                        <PdfCard key={index} exam={exam} pdfType={PdfType.Uploads} />
                    )) : <div>Al momento non ci sono documenti presenti.</div>
                }
            </div>
            {isLoading && <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center"><LoadingSpinner /></div>}
        </div>
    )
}

export default UploadedFromYou
