import CloseButton from "../../../../components/CloseButton"

interface Props {
    onClick: () => void,
}

const ChangePasswordDialog: React.FC<Props> = ({
    onClick,
}) => {
    return (
        <div className="fixed inset-0 bg-black bg-opacity-30 flex items-center justify-center z-50">
            <div className="w-[90%] md:w-[500px] rounded-[20px] border border-solid border-holifya-grey bg-white shadow-platform px-[20px] pt-[10px] pb-[20px]">
                <div className="w-full space-y-[10px] flex flex-col items-end">
                    <CloseButton onClick={onClick} />
                    <div className="font-normal text-[16px] leading-[22px]">
                        Abbiamo aggiornato la piattaforma ed è necessario un cambio password.<br></br>
                        Clicca su “Non riesci ad accedere?”, inserisci la tua mail associata al tuo account e reimposta la password per accedere.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ChangePasswordDialog
