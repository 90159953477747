import { createContext, ReactNode, useContext, useEffect, useState } from "react";

interface RegistrationState {
    registrationEmail: string | null;
    password: string | null;
    isUnconfirmed: UnconfirmDirection | null;
}

interface RegistrationContextType {
    registrationData: RegistrationState;
    saveData: (registrationEmail: string, password: string, isUnconfirmed: UnconfirmDirection) => void;
    clearData: () => void;
}

const RegistrationContext = createContext<RegistrationContextType | undefined>(undefined);

export const RegistrationProvider: React.FC<{ children: ReactNode }> = ({
    children,
}) => {
    const [registrationData, setRegistrationData] = useState<RegistrationState>({
        registrationEmail: localStorage.getItem("registrationEmail"),
        password: localStorage.getItem("password"),
        isUnconfirmed: localStorage.getItem("isUnconfirmed") as UnconfirmDirection | null,
    });

    useEffect(() => {
        if (registrationData.registrationEmail) {
            localStorage.setItem('registrationEmail', registrationData.registrationEmail);
        } else {
            localStorage.removeItem('registrationEmail');
        }
        
        if (registrationData.password) {
            localStorage.setItem('password', registrationData.password);
        } else {
            localStorage.removeItem('password');
        }

        if (registrationData.isUnconfirmed !== null) {
            localStorage.setItem("isUnconfirmed", registrationData.isUnconfirmed);
        } else {
            localStorage.removeItem("isUnconfirmed");
        }
    }, [registrationData]);

    const saveData = (registrationEmail: string, password: string, isUnconfirmed: UnconfirmDirection) => {
        setRegistrationData({ registrationEmail, password, isUnconfirmed });
    };

    const clearData = () => {
        setRegistrationData({ registrationEmail: null, password: null, isUnconfirmed: null });
    };

    return (
        <RegistrationContext.Provider value={{ registrationData, saveData, clearData }}>
            {children}
        </RegistrationContext.Provider>
    );
};

export const useRegistrationData = (): RegistrationContextType => {
    const context = useContext(RegistrationContext);
    if (context === undefined) {
        throw new Error('useRegistrationData must be used within a RegistrationProvider');
    }
    return context;
};

export enum UnconfirmDirection {
    Registration = "registration",
    LoginAttempt = "login-attempt",
    LoginCreateButton = "login-create-button",
    None = "none",
}
