import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useConsults } from "../../../utils/context/ConsultsContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { Folder } from "../components/Folder"
import FolderBox from "../components/FolderBox"
import ConsultCard from "./components/ConsultCard"

const Equipe = () => {
  const { auth } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const { consultsData } = useConsults()
  const mostRecentAppointment = consultsData.mostRecentFutureAppointment
  const navigate = useNavigate()

  const folders = [
    {
      folder: Folder.Appointments,
      navigateTo: "your-appointments",
      eventKey: "your_appointments_folder_clicked",
    },
    {
      folder: Folder.Experts,
      navigateTo: "your-experts",
      eventKey: "your_experts_folder_clicked",
    },
  ];

  const handleFolderClick = (navigateTo: string, eventKey: string) => {
    navigate(navigateTo);
    if (!email) return;
    saveEvent(
      email,
      eventKey,
      (success) => {
        console.log(success);
      },
      (error) => {
        console.log(error);
      },
      "equipe_page"
    );
  };

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "equipe_page",
    )
  }, [])

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col space-y-[20px]">
      <div className="font-bold text-[25px] leading-[33px]">
        Equipe Medica
      </div>
      {
        (mostRecentAppointment !== null && mostRecentAppointment !== undefined) &&
        <div>
          <div className="font-bold text-[12px] leading-[20px]">
            Prossimo appuntamento
          </div>
          <ConsultCard
            appointment={mostRecentAppointment}
          />
        </div>
      }
      <div className="flex flex-col md:flex-row md:justify-between">
        {folders.map(({ folder, navigateTo, eventKey }, index) => (
          <div key={index} className="w-full mb-[20px] md:w-[49%] md:mb-[0]">
            <FolderBox
              folder={folder}
              onClick={() => handleFolderClick(navigateTo, eventKey)}
            />
          </div>
        ))}
      </div>
      <div className="p-[10px]" />
    </div>
  )
}

export default Equipe
