import { FormEvent, useEffect, useState } from "react"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import PasswordInputElement from "../../../components/input/v2/PasswordInputElement"
import TextInputElement from "../../../components/input/v2/TextInputElement"
import { changePassword } from "../../../utils/apis/auth-service"
import { saveEvent, updateUserInfo } from "../../../utils/apis/user-service"
import { PASSWORD_REGEX } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { PasswordInputIcon } from "../../../utils/enums/PasswordInputIcon"
import { PasswordInputType } from "../../../utils/enums/PasswordInputType"

const PersonalData = () => {
  const { auth } = useAuth()
  const userId = auth.userId
  const idToken = auth.idToken
  const accessToken = auth.accessToken

  const { userData, updateUserData } = useUserData()
  const username = userData.name
  const familyName = userData.familyName
  const email = userData.email
  const phoneNumber = userData.phoneNumber
  const taxCode = userData.taxCode

  const canUpdate = userId && idToken

  const [dataInputs, setDataInputs] = useState<{ [key: string]: string }>({
    name: username !== null ? username : '',
    familyName: familyName !== null ? familyName : '',
    email: email !== null ? email : '',
    phoneNumber: phoneNumber !== null ? phoneNumber : '',
    taxCode: taxCode !== null ? taxCode : '',
  });

  const [passwordInputs, setPasswordInputs] = useState<{ [key: string]: string }>({
    oldPassword: '',
    newPassword: '',
  });

  const handleDataInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setDataInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
    setIsUpdateDataButtonDisabled(false)
  };

  const handlePasswordInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;

    setPasswordInputs((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const [isUpdateDataButtonDisabled, setIsUpdateDataButtonDisabled] = useState(true)
  const [isUpdateDataLoading, setIsUpdateDataLoading] = useState(false)
  const isUpdatePasswordButtonEnabled = PASSWORD_REGEX.test(passwordInputs.oldPassword) && PASSWORD_REGEX.test(passwordInputs.newPassword)
  const [isUpdatePasswordLoading, setIsUpdatePasswordLoading] = useState(false)

  const onUpdateDataClick = (e: FormEvent) => {
    e.preventDefault()
    setIsUpdateDataLoading(true)
    canUpdate && updateUserInfo(
      userId,
      idToken,
      dataInputs.name,
      dataInputs.familyName,
      dataInputs.phoneNumber,
      dataInputs.taxCode,
      (user) => {
        updateUserData(
          user.data.name,
          user.data.family_name,
          user.email,
          user.data.mobile_phone,
          user.tax_number,
        )
        setIsUpdateDataLoading(false)
      },
      (error) => {
        console.log(error)
        setIsUpdateDataLoading(false)
      }
    )
  }

  const onUpdatePasswordClick = (e: FormEvent) => {
    e.preventDefault()
    setIsUpdatePasswordLoading(true)
    changePassword(
      accessToken!,
      passwordInputs.oldPassword,
      passwordInputs.newPassword,
      (success) => {
        console.log(success)
        setIsUpdatePasswordLoading(false)
      },
      (error) => {
        setIsUpdatePasswordLoading(false)
        console.log(error)
      },
    )
  }

  useEffect(() => {
    if (!email || !idToken) return
    saveEvent(
      email,
      EventType.PageView,
      (success) => console.log(success),
      (error) => { console.log(error) },
      "personal_data_page",
    )
  }, [])

  // ********************************************************************************

  return (
    <div className="w-full h-full flex flex-col">
      <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
        Dati personali
      </div>
      <form
        className="pb-[20px]"
        onSubmit={onUpdateDataClick}
      >
        <TextInputElement
          name="name"
          label="Nome"
          errorText={""}
          inputValue={dataInputs.name}
          onChange={handleDataInputChange}
          placeholder="Nome"
        />
        <TextInputElement
          name="familyName"
          label="Cognome"
          errorText={""}
          inputValue={dataInputs.familyName}
          onChange={handleDataInputChange}
          placeholder="Cognome"
        />
        <TextInputElement
          name="email"
          label="Email"
          errorText={""}
          inputValue={dataInputs.email}
          onChange={handleDataInputChange}
          placeholder="Indirizzo email"
          disabled={true}
        />
        <TextInputElement
          name="phoneNumber"
          label="Numero di telefono"
          errorText={""}
          inputValue={dataInputs.phoneNumber}
          onChange={handleDataInputChange}
          placeholder="Numero di telefono"
        />
        <TextInputElement
          name="taxCode"
          label="Codice fiscale"
          errorText={""}
          inputValue={dataInputs.taxCode}
          onChange={handleDataInputChange}
          placeholder="Codice fiscale"
        />
        <div className="w-full h-[50px]">
          <PrimaryButton
            text="Conferma"
            pointer={!isUpdateDataButtonDisabled}
            disabled={isUpdateDataButtonDisabled}
            fontSize={18}
            isLoading={isUpdateDataLoading}
          />
        </div>
      </form>
      <div className="font-bold text-[15px] leading-[33px] my-[20px]">
        Modifica password
      </div>
      <form
        className="pb-[20px]"
        onSubmit={onUpdatePasswordClick}
      >
        <PasswordInputElement
          name="oldPassword"
          label="Password attuale"
          errorText=""
          inputValue={passwordInputs.oldPassword}
          onChange={handlePasswordInputChange}
          placeholder="Password attuale"
          type={PasswordInputType.Password}
          image={PasswordInputIcon.ShowDisabled}
          toggleTypeEnabled={passwordInputs.oldPassword !== ""}
        />
        <div className="font-semibold text-[12px] leading-[20px] text-holifya-blue mt-[5px] mb-[20px]">
          Una password valida è composta da almeno 8 caratteri di cui: almeno una Maiuscola, una minuscola, un numero e un simbolo speciale (!$%& ...)
        </div>
        <PasswordInputElement
          name="newPassword"
          label="Nuova password"
          errorText=""
          inputValue={passwordInputs.newPassword}
          onChange={handlePasswordInputChange}
          placeholder="Nuova Password"
          type={PasswordInputType.Password}
          image={PasswordInputIcon.ShowDisabled}
          toggleTypeEnabled={PASSWORD_REGEX.test(passwordInputs.oldPassword) && passwordInputs.newPassword !== ""}
          disabled={!PASSWORD_REGEX.test(passwordInputs.oldPassword)}
        />
        <div className="w-full h-[50px] mt-[10px]">
          <PrimaryButton
            text="Conferma"
            pointer={isUpdatePasswordButtonEnabled}
            disabled={!isUpdatePasswordButtonEnabled}
            fontSize={18}
            isLoading={isUpdatePasswordLoading}
          />
        </div>
      </form>
    </div>
  )
}

export default PersonalData
