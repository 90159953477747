import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { loginApi } from "../../../utils/apis/auth-service";
import { useAuth } from "../../../utils/context/AuthContext";
import { useRegistrationData } from "../../../utils/context/RegistrationContext";
import LoadingSpinner from "../../new-platform/components/Loaders";

const RegistrationSuccess = () => {

    const navigate = useNavigate();
    const { login } = useAuth();
    const { registrationData, clearData } = useRegistrationData()
    const email = registrationData.registrationEmail
    const password = registrationData.password

    const callLogin = async (email: string, password: string) => {
        try {
            const result = await loginApi(email, password)
            if (result.idToken && result.accessToken && result.userId && result.refreshToken && result.expiryTime) {
                login(result.idToken, result.userId, result.accessToken, result.refreshToken, result.expiryTime);
                navigate("/fetch-user-data");
            } else {
                throw new Error("Missing token or user information in the response.");
            }
        } catch (error) {
            navigate("/login")
        } finally {
            clearData()
        }
    }

    useEffect(() => {
        if (!email || !password) return
        callLogin(email, password)
    }, [])

    // ********************************************************************************

    return (
        <div className="App">
            <div className="w-[90%] h-full md:w-[392px] flex items-center justify-center">
                <div className="w-full py-[20px] bg-white flex flex-col items-center space-y-[20px]">
                    <div className="font-bold text-[30px] leading-[38px] text-center">
                        Account creato correttamente
                    </div>
                    <div className="font-normal text-[18px] leading-[26px] text-center">
                        Stai per essere reindirizzato alla tua Area Personale
                    </div>
                    <LoadingSpinner />
                </div>
            </div>
        </div>
    )
}

export default RegistrationSuccess
