import { MealPlan } from "../api-objects/HealthPlan";
import { BASE_URL, requestOptionsGET } from "../Constants";

export async function getMealPlan(
    userId: string,
    idToken: string,
): Promise<MealPlan> {
    try {
        const response = await fetch(`${BASE_URL}/api/meal-plans/user/${userId}`, requestOptionsGET(idToken));
        if (response.ok) {
            const mealPlan: MealPlan = await response.json();
            return mealPlan; 
        } else if (response.status === 401) {
            throw new Error("Session expired");
        } else if (response.status === 404) {
            throw new Error(`Nessun meal plan trovato per l'utente: ${userId}`);
        } else {
            throw new Error("Errore nel recuperare il meal plan");
        }
    } catch (error) {
        if (error instanceof Error)
            if (error.message.includes("Session expired")) {
                throw new Error("Session expired");
            } else if (error.message.includes("Nessun meal plan trovato")) {
                throw new Error(error.message);
            }
        throw new Error("An unexpected error occurred");
    }
}