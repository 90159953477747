import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { logoutAPI } from "../../../utils/apis/auth-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { ENVIRONMENT, NAVBAR_LOGOS, NEW_PLATFORM_DIAGNOSTICA_LOGO, NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS, NEW_PLATFORM_EQUIPE_LOGO, NEW_PLATFORM_EQUIPE_LOGO_FOCUS, NEW_PLATFORM_HEALTH_PLAN_LOGO, NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS, NEW_PLATFORM_HOME_LOGO, NEW_PLATFORM_HOME_LOGO_FOCUS, NEW_PLATFORM_MENU_LOGO, NEW_PLATFORM_MENU_LOGO_FOCUS } from "../../../utils/Constants"
import { useAuth } from "../../../utils/context/AuthContext"
import { useTutorial } from "../../../utils/context/TutorialContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { useProducts } from "../../../utils/context/UserProductsContext"
import { Environment } from "../../../utils/enums/Environment"
import { EventType, SourcePage } from "../../../utils/enums/EventType"
import { TutorialType } from "../../../utils/enums/TutorialType"
import { NavBarElement } from "./NavBarElement"

interface Props {
  currentItem: NavBarElement,
  disabled?: boolean,
}

const NavBar: React.FC<Props> = ({
  currentItem,
  disabled = false,
}) => {
  const navigate = useNavigate()
  const { auth, logout } = useAuth()
  const idToken = auth.idToken
  const { userData } = useUserData()
  const email = userData.email
  const { tutorial } = useTutorial()
  const tutorialMenu = tutorial.tutorial
  const { productsData } = useProducts()
  const membership = productsData.membership
  const dnaTest = productsData.dnaTest

  const premiumRequirements = membership && ENVIRONMENT === Environment.DEVELOP
  const dnaTestUser = dnaTest && !membership && ENVIRONMENT === Environment.DEVELOP

  const [menuOpen, setMenuOpen] = useState(false)

  const onLogoutClick = () => {
    if (!email) return
    logoutAPI(
      email,
      () => {
        logout()
      },
      (error) => {
        console.log(error)
      }
    )
    email && saveEvent(
      email,
      "logout_button_clicked",
      (success) => { console.log(success) },
      (error) => { console.log(error) },
      "menu"
    )
  }

  const tutorialPages: Record<TutorialType, string> = {
    [TutorialType.DNA_TEST]: '/how-it-works-dna',
    [TutorialType.CONSULT]: '/consult',
    [TutorialType.SUB_HEALTH]: '/how-it-works-sub-health',
    [TutorialType.SUB_NUTRITION]: '/how-it-works-sub-nutrition',
  };

  const onHowItWorksClick = () => {
    const page = tutorialPages[tutorialMenu!.tutorial_type];
    if (page) navigate(page);
  }

  useEffect(() => {
    const images = [
      NEW_PLATFORM_HOME_LOGO,
      NEW_PLATFORM_HOME_LOGO_FOCUS,
      NEW_PLATFORM_HEALTH_PLAN_LOGO,
      NEW_PLATFORM_HEALTH_PLAN_LOGO_FOCUS,
      NEW_PLATFORM_DIAGNOSTICA_LOGO,
      NEW_PLATFORM_DIAGNOSTICA_LOGO_FOCUS,
      NEW_PLATFORM_EQUIPE_LOGO,
      NEW_PLATFORM_EQUIPE_LOGO_FOCUS,
      NEW_PLATFORM_MENU_LOGO,
      NEW_PLATFORM_MENU_LOGO_FOCUS,
    ]

    images.forEach((src) => {
      const img = new Image()
      img.src = src
    })
  }, [])

  const handleNavigation = (path: string, eventType: string, sourcePage: string) => {
    navigate(path);
    if (currentItem !== eventType && email) {
      saveEvent(
        email,
        eventType,
        (success) => console.log(success),
        (error) => console.log(error),
        sourcePage
      );
    }
  };

  const sourcePageMap = {
    [NavBarElement.Home]: SourcePage.HomePage,
    [NavBarElement.HealthPlan]: SourcePage.HealthPlan,
    [NavBarElement.Diagnostica]: SourcePage.Diagnostica,
    [NavBarElement.Equipe]: SourcePage.Equipe,
    [NavBarElement.Menu]: SourcePage.Menu,
    [NavBarElement.MenuElement]: SourcePage.MenuElement,
  };

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
    if (email) {
      const eventType =
        currentItem === NavBarElement.Menu
          ? EventType.MenuCloseNavbarElementClicked
          : EventType.MenuOpenNavbarElementClicked;

      saveEvent(
        email,
        eventType,
        (success) => console.log(success),
        (error) => console.error(error),
        sourcePageMap[currentItem]
      );
    }
    console.log(currentItem)
  };

  const navItems = [
    {
      element: NavBarElement.Home,
      text: "Home",
      onClick: () => handleNavigation("/home", EventType.HomeNavbarElementClicked, sourcePageMap[currentItem]),
      imagekey: "HOME" as keyof typeof NAVBAR_LOGOS,
    },
    {
      element: NavBarElement.HealthPlan,
      text: "Health Plan",
      onClick: () => handleNavigation("/health-plan", EventType.HealthPlanNavbarElementClicked, sourcePageMap[currentItem]),
      imagekey: "HEALTH_PLAN" as keyof typeof NAVBAR_LOGOS,
    },
    {
      element: NavBarElement.Diagnostica,
      text: "Diagnostica",
      onClick: () => handleNavigation("/diagnostica", EventType.DiagnosticaNavbarElementClicked, sourcePageMap[currentItem]),
      imagekey: "DIAGNOSTICA" as keyof typeof NAVBAR_LOGOS,
    },
    {
      element: NavBarElement.Equipe,
      text: "Equipe",
      onClick: () => handleNavigation("/equipe", EventType.EquipeNavbarElementClicked, sourcePageMap[currentItem]),
      imagekey: "EQUIPE" as keyof typeof NAVBAR_LOGOS,
    },
    {
      element: NavBarElement.Menu,
      text: "Menu",
      onClick: handleMenuClick,
      imagekey: "MENU" as keyof typeof NAVBAR_LOGOS,
    },
  ];

  const handleImage = (element: NavBarElement): string => {
    const navBarLogo = NAVBAR_LOGOS[element.toUpperCase().replace("-", "_") as keyof typeof NAVBAR_LOGOS];

    if (!navBarLogo) {
      console.error(`No logo defined for element: ${element}`);
      return "";
    }

    if (element === NavBarElement.Menu) {
      if (menuOpen) {
        return navBarLogo.OPEN
      } else if (currentItem === NavBarElement.MenuElement) {
        return navBarLogo.FOCUS
      } else {
        return navBarLogo.DEFAULT
      }
    }

    return (currentItem === element && !menuOpen) ? navBarLogo.FOCUS : navBarLogo.DEFAULT;
  };

  // ********************************************************************************

  return (
    <div className={`w-full h-[90px] ${premiumRequirements ? "bg-holifya-green" : (dnaTestUser ? "bg-holifya-red" : "bg-holifya-navy-blue")} flex items-center justify-center ${disabled && "opacity-50 pointer-events-none"} md:w-[100px] md:h-full md:flex-col`}>
      <div className="w-[90%] h-full flex items-center justify-between md:flex-col md:h-[400px]">
        {navItems.map(({ element, text, onClick, imagekey }) => (
          <NavBarItem
            key={element}
            image={handleImage(element)}
            text={text}
            onClick={onClick}
          />
        ))}
      </div>
      {
        menuOpen &&
        <MenuOverlay
          onMenuClose={() => setMenuOpen(false)}
          onHowItWorksClick={onHowItWorksClick}
          onLogoutClick={onLogoutClick}
        />
      }
    </div>
  )
}

export default NavBar

const MenuOverlay: React.FC<{
  onMenuClose: () => void,
  onHowItWorksClick: () => void,
  onLogoutClick: () => void,
}> = ({
  onMenuClose,
  onHowItWorksClick,
  onLogoutClick,
}) => {
  const { userData } = useUserData()
  const username = userData.name
  const familyName = userData.familyName
  const email = userData.email

  const navigate = useNavigate()
  return(
    <>
          <div className="hidden md:block cursor-pointer fixed top-0 left-0 w-full h-full bg-black opacity-30 z-[19] md:left-[100px]" onClick={onMenuClose} />
          <div className="fixed top-0 left-0 w-full h-[calc(100%-90px)] bg-holifya-menu z-[20] flex flex-col items-center justify-center overflow-y-auto scrollbar-hide md:left-[100px] md:top-0 md:w-[393px] md:h-full">
            <div className="w-[90%] h-full p-[20px] flex flex-col md:justify-between">
              <div>
                <div className="w-full h-[35px] flex items-center justify-between">
                  <div className="leading-none">
                    <img src="/images/new-platform/logos/holifya-logo.svg" />
                  </div>
                  <div className="cursor-pointer" onClick={onMenuClose}>
                    <img src="/images/new-platform/components/x.svg" />
                  </div>
                </div>
                <div className="mt-[20px] mb-[10px]">
                  <div className="font-bold text-[25px] leading-[33px]">
                    {username} {familyName}
                  </div>
                </div>
                <div className="mb-[20px]">
                  <div className="font-normal text-[14px] leading-[33px] no-underline text-inherit pointer-events-none cursor-default">
                    <span>{email}</span>
                  </div>
                </div>
                <div className="w-[300px] h-[50px]">
                  <PrimaryButton
                    text="Acquista"
                    pointer={true}
                    disabled={false}
                    fontSize={18}
                    onClick={() => {
                      navigate("/purchase")
                      onMenuClose()
                    }}
                  />
                </div>
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Menu
                  </div>
                </div>
                <MenuItem
                  text={`${"\u{1F642}"} Dati personali`}
                  onClick={() => {
                    navigate("/menu/personal-data")
                    onMenuClose()
                  }}
                />
                <MenuItem
                  text={`${"\u{1F4B3}"} Acquisti e metodi di pagamento`}
                  onClick={() => {
                    navigate("/menu/payment-methods-and-purchases")
                    onMenuClose()
                  }}
                />
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Assistenza
                  </div>
                </div>
                <MenuItem
                  text={`${"\u{1F6E0}"} Come funziona`}
                  onClick={onHowItWorksClick}
                />
                <MenuItem
                  text={`${"\u{1F64B}"} Domande frequenti`}
                  onClick={() =>
                    window.open("https://www.holifya.com/pages/faqs", "_blank")
                  }
                />
                <MenuItem text={`${"\u{1F4E7}"} Scrivici`} mailTo="support@holifya.com" />
                <div className="my-[20px]">
                  <div className="font-bold text-[12px] leading-[20px] text-holifya-blue">
                    Note legali
                  </div>
                </div>
                <MenuItem
                  text={`⚖️ Termini e condizioni`}
                  href="/assets/new-platform/personal-data-treatment.pdf"
                />
                <MenuItem
                  text={`⚖️ Privacy policy`}
                  href="/assets/new-platform/privacy-policy.pdf"
                />
              </div>
              <div>
                <div className="w-full h-[1.5px] bg-black" />
                <MenuItem text={`⬅️ Esci`} onClick={onLogoutClick} />
                <div className="height_20" />
              </div>
            </div>
          </div>
        </>
  )
}

const NavBarItem: React.FC<{
  image: string,
  text: string,
  onClick: () => void, 
}> = ({
  image,
  text,
  onClick,
}) => {
  return (
    <div className="h-[50%] flex flex-col items-center justify-between cursor-pointer md:h-fit" onClick={onClick}>
      <div className="w-[20px] h-[20px] flex justify-center md:mb-[10px]">
        <img src={image} alt={text} />
      </div>
      <div className="font-bold text-[10px] leading-[15px] text-white">{text}</div>
    </div>
  )
}

const MenuItem: React.FC<{
  text: string,
  onClick?: () => void,
  href?: string,
  mailTo?: string,
}> = ({
  text,
  onClick,
  href,
  mailTo,
}) => {
  const isLink = !!href;
  const isMail = !!mailTo;

  const Component = isLink || isMail ? 'a' : 'div';

  return (
    <Component
      href={isLink ? href : (isMail ? `mailto:${mailTo}` : undefined)}
      onClick={!isLink && !isMail ? onClick : undefined}
      className="my-[30px] font-bold text-[16px] leading-[20px] no-underline cursor-pointer"
      style={{ color: 'black', display: 'block', textDecoration: 'none' }}
      target={isLink || isMail ? "_blank" : undefined} // Open PDFs in a new tab
      rel={isLink ? "noopener noreferrer" : undefined}
    >
      {text}
    </Component>
  )
}
