import { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Exam } from "../../../utils/api-objects/Exam"
import { getAllHealthPlanExamsByType } from "../../../utils/apis/emr-service"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useHealthPlan } from "../../../utils/context/HealthPlanContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { BucketFolder } from "../../../utils/enums/BucketFolder"
import { EventType } from "../../../utils/enums/EventType"
import { LoadingOverlay } from "../components/Loaders"
import { Folder } from "./../components/Folder"
import FolderBox from "./../components/FolderBox"
import { PdfCard, PdfType } from "./../components/PdfCard"

const HealthPlan = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { healthPlan, updateHealthPlan } = useHealthPlan()
    const hPlan = healthPlan.healthPlan
    const topHeroFood = hPlan?.data.top_hero_foods
    const topIntegratori = hPlan?.data.top_integratori
    const nutriRecommendations = hPlan?.data.nutrition_recommendations
    const navigate = useNavigate()

    const [report, setReport] = useState<Exam>()
    const [isLoading, setIsLoading] = useState(true)

    const onMealPlanClicked = () => {
        navigate("meal-plan")
        if (!email) return
        saveEvent(
            email,
            EventType.MealPlanFolderClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_plan_page",
        )
    }

    useEffect(() => {
        if (!userId || !idToken) {
            return
        }
        getAllHealthPlanExamsByType(
            userId,
            idToken,
            BucketFolder.HealthPlan,
            (exams) => {
                setReport(exams[0])
                setIsLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLoading(false)
            }
        );

        if (!email) return

        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "health_plan_page"
        )
    }, []);

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col">
            <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                Health Plan
            </div>
            {
                report ?
                    <div>
                        <PdfCard
                            exam={report}
                            pdfType={PdfType.HealthPlan}
                        />
                        <div className="p-[10px]" />
                        <div className="flex flex-col md:flex-row md:justify-between">
                            {
                                nutriRecommendations && nutriRecommendations.length > 0 && <HealthPlanCard inputs={nutriRecommendations} type={HPCategory.NutriRecommendations} />
                            }
                            {
                                topHeroFood && topHeroFood.length > 0 && <HealthPlanCard inputs={topHeroFood} type={HPCategory.Food} />
                            }
                            {
                                topIntegratori && topIntegratori.length > 0 && <HealthPlanCard inputs={topIntegratori} type={HPCategory.Integratori} />
                            }
                        </div>
                    </div> :
                    <div className="font-normal text-[16px] leading-[26px] mb-[20px]">
                        Il tuo health plan sarà disponibile dopo il tuo consulto con nutrizionista.
                    </div>
            }
            <div className="w-full md:w-[50%]">
                <FolderBox
                    folder={Folder.MealPlan}
                    onClick={onMealPlanClicked}
                />
            </div>
            <div className="p-[10px]" />
            {isLoading && <LoadingOverlay />}
        </div>
    )
}

export default HealthPlan

export enum HPCategory {
    Food = "hero-food",
    NutriRecommendations = "nutri-recommendations",
    Integratori = "integratori",
}

interface Props {
    inputs: string[],
    type: HPCategory,
}

export const HealthPlanCard: React.FC<Props> = ({
    inputs,
    type,
}) => {
    const handleTitle = () => {
        switch (type) {
            case HPCategory.NutriRecommendations:
                return "👩‍ Top nutrition recommendation per te"
            case HPCategory.Food:
                return `🥑 Top ${inputs.length} hero foods per te`
            case HPCategory.Integratori:
                return "💊 Top integratori per te"
        }
    }

    // ********************************************************************************

    return (
        <div className="rounded-[15px] shadow-platform px-[20px] py-[10px] mb-[20px] md:w-[32%]">
            <div className="font-bold text-[12px] leading-[33px]">{handleTitle()}</div>
            {
                inputs.map((input, index) => (
                    <div className="ml-[20px] mt-[10px]" key={index}>
                        {input}
                    </div>
                ))
            }
        </div>
    )
}
