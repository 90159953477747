import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Exam } from "../../../../utils/api-objects/Exam";
import { getAllExamsByType } from "../../../../utils/apis/emr-service";
import { useAuth } from "../../../../utils/context/AuthContext";
import { BucketFolder } from "../../../../utils/enums/BucketFolder";
import BackButton from "../../components/BackButton";
import { LoadingOverlay } from "../../components/Loaders";
import { PdfCard, PdfType } from "../../components/PdfCard";

const UploadedForYou = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const navigate = useNavigate()

    const [isLoading, setIsLoading] = useState(true)
    const [reports, setReports] = useState<Exam[]>()

    useEffect(() => {
        if (!userId || !idToken) return
        getAllExamsByType(
            userId,
            idToken,
            BucketFolder.UploadedFromExpert,
            (exams) => {
                setReports(exams)
                setIsLoading(false)
            },
            (error) => {
                console.error(error)
                setIsLoading(false)
            }
        );
    }, []);

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Test e referti"
                    onClick={() => navigate("/diagnostica/tests-and-reports")}
                />
                <div className="font-bold text-[25px] leading-[33px]">
                    Caricati per te
                </div>
            </div>
            <div className="font-normal text-[16px] leading-[26px]">
                In questa cartella puoi accedere a tutti i documenti che la tua Medical Equipe ha condiviso con te.
            </div>
            {
                reports && reports.map((report, index) => (
                    <PdfCard key={index} exam={report} pdfType={PdfType.Uploads} />
                ))
            }
            {isLoading && <LoadingOverlay />}
        </div>
    )
}

export default UploadedForYou
