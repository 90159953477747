import { useEffect } from "react"
import { useNavigate } from "react-router-dom"
import { handleDayOfMonth, handleHourAndMinutes, handleMonthString } from "../../../utils/Functions"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useConsults } from "../../../utils/context/ConsultsContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { ExpertGenderRole, ExpertRole } from "../../../utils/enums/Experts"
import { Appointment } from "../components/Appointment"
import BackButton from "../components/BackButton"
import ConsultCard from "./components/ConsultCard"

const YourAppointments = () => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const { consultsData } = useConsults()
    const pastAppointments = consultsData.allPastAppointments ? [...consultsData.allPastAppointments].reverse() : [];
    const allFutureAppointments = consultsData.allFutureAppointments
    const navigate = useNavigate()

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "your_appointments_page",
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col space-y-[20px]">
            <div className="mt-[10px] space-y-[10px]">
                <BackButton
                    text="Torna a Equipe Medica"
                    onClick={() => navigate("/equipe")} />
                <div className="font-bold text-[25px] leading-[33px]">
                    I tuoi appuntamenti
                </div>
            </div>
            {
                allFutureAppointments !== null && allFutureAppointments.length > 0 ?
                    <div className="w-full flex-shrink-0">
                        <div className="font-bold text-[12px] leading-[20px]">Prossimi appuntamenti</div>
                        {
                            allFutureAppointments.map((appointment, index) => (
                                <ConsultCard key={index} appointment={appointment} />
                            ))
                        }
                    </div> :
                    <div>
                        Qui puoi trovare tutti gli appuntamenti che hai prenotato, passati e futuri.
                    </div>
            }
            {
                pastAppointments !== null && pastAppointments.length > 0 &&
                <div className="w-full mt-[20px]">
                    <div className="font-bold text-[12px] leading-[20px]">Appuntamenti passati</div>
                    {
                        pastAppointments.map((appointment, index) => (
                            <PastAppointmentCard key={index} appointment={appointment} />
                        ))
                    }
                </div>
            }
        </div>
    )
}

export default YourAppointments

interface Props {
    appointment: Appointment,
}

const PastAppointmentCard: React.FC<Props> = ({
    appointment,
}) => {
    return (
        <div className="w-full h-[178px] bg-[#F5F5F5] my-[10px] rounded-[15px] flex items-center justify-center">
            <div className="w-[90%] h-[70%] flex flex-col justify-between">
                <div className="font-bold text-[12px] leading-[20px]">Videoconsulto</div>
                <div className="font-normal text-[35px] leading-[35px] flex">
                    <div className="font-bold mr-[10px]">
                        {handleDayOfMonth(appointment.date)} {handleMonthString(appointment.date)}
                    </div>
                    <div>
                        {handleHourAndMinutes(appointment.date)}
                    </div>
                </div>
                <div className="w-full flex items-end justify-between">
                    <div className="font-bold text-[12px] leading-[20px]">
                        <div>
                            {appointment.expert?.data.sex ? ExpertGenderRole.Dottssa : ExpertGenderRole.Dott}
                        </div>
                        <div className="text-[20px]">
                            {appointment.expert?.data.name} {appointment.expert?.data.family_name}
                        </div>
                    </div>
                    <div className="font-bold text-[14px] leading-[20px] text-holifya-blue">
                        {appointment.expert?.role === ExpertRole.Nutritionist ? "Nutrizionista" : "Medico"}
                    </div>
                </div>
            </div>
        </div>
    )
}
