import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PrimaryButton from "../../../components/buttons/PrimaryButton";
import { SortingItem } from "../../../utils/api-objects/SortingItem";
import { UserProduct } from "../../../utils/api-objects/UserProduct";
import { assignMedicalUserToPatient, getMedicalUserByRole, getNutritionistByPatientId } from "../../../utils/apis/medicaluser-service";
import { assignSortingItems, createProduct, getMembership, getProducts, getSortingItems, redeemSortingItems } from "../../../utils/apis/order-service";
import { saveEvent } from "../../../utils/apis/user-service";
import { ENVIRONMENT } from "../../../utils/Constants";
import { useAuth } from "../../../utils/context/AuthContext";
import { useExpert } from "../../../utils/context/ExpertContext";
import { useUserData } from "../../../utils/context/UserDataContext";
import { useProducts } from "../../../utils/context/UserProductsContext";
import { CreateProductChannel } from "../../../utils/enums/CreateProductChannel";
import { Environment } from "../../../utils/enums/Environment";
import { EventType } from "../../../utils/enums/EventType";
import { ExpertRole } from "../../../utils/enums/Experts";
import { ProductType } from "../../../utils/enums/ProductType";
import { createExpertFromCalendlyExpert, getRandomNutritionist, handleShopifyProductName } from "../../../utils/Functions";
import { Folder } from "../components/Folder";
import FolderBox from "../components/FolderBox";
import LoadingSpinner from "../components/Loaders";
import { WarningDialog } from "../components/WarningDialog";
import AssignPopup from "./components/AssignPopup";
import RedeemPopup from "./components/RedeemPopup";

const PaymentMethodsAndPurchases = () => {
    const navigate = useNavigate()
    const { auth } = useAuth()
    const idToken = auth.idToken
    const userId = auth.userId
    const { userData } = useUserData()
    const email = userData.email
    const { productsData, updateMembership, updateDnaTest } = useProducts()
    const membership = productsData.membership
    const dnaTest = productsData.dnaTest
    const { experts, updateNutritionist } = useExpert()
    const nutritionist = experts.nutritionist

    const [isLoading, setIsLoading] = useState(true)
    const [sortingItems, setSortingItems] = useState<SortingItem[]>()
    const [products, setProducts] = useState<UserProduct[]>()
    const [error, setError] = useState<string>("");

    const [refreshData, setRefreshData] = useState(false);

    const triggerRefresh = () => setRefreshData(prev => !prev);

    const fetchData = useCallback(async () => {
        if (!idToken || !userId || !email) return;

        if (!nutritionist) {
            try {
                const nutri = await getNutritionistByPatientId(idToken, userId)
                updateNutritionist(nutri)
            } catch (error) {
                console.log(error)
            }
        }
        if (!membership) {
            getMembership(
                userId,
                idToken,
                (membership) => {
                    updateMembership(membership)
                },
                (error) => {
                    console.log(error)
                },
            )
        }

        if (!dnaTest) {
            const products = await getProducts(userId, idToken);
            products.forEach((product) => {
                if (product.product_type === ProductType.DnaTest) {
                    updateDnaTest(product.data.channel)
                }
            })
        }

        setIsLoading(true);
        setError("");

        try {
            const [sortingResponse, productsResponse] = await Promise.all([
                getSortingItems(email, idToken),
                getProducts(userId, idToken),
            ]);

            setSortingItems(sortingResponse);
            const filteredProducts = productsResponse.filter(
                (product) => product.data.channel !== CreateProductChannel.ShopifyPurchase
            );
            setProducts(filteredProducts);

        } catch (error) {
            console.error("An error occurred while fetching data:", error);
            setError("error.message");
        } finally {
            setIsLoading(false);
        }
    }, [idToken, userId, email]);

    useEffect(() => {
        fetchData();
    }, [fetchData, refreshData]);

    const onManageSubscriptionsClick = () => {
        switch (ENVIRONMENT) {
            case Environment.DEVELOP:
                return window.open("https://customers.shop.paywhirl.com/58970734682/account/")
            case Environment.STAGE:
                return
            case Environment.PRODUCTION:
                return window.open("https://customers.shop.paywhirl.com/64719094024/account/")
        }
    }

    useEffect(() => {
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.PageView,
            (success) => console.log(success),
            (error) => { console.log(error) },
            "payment_method_and_purchases_page",
        )
    }, [])

    // ********************************************************************************

    return (
        <div className="w-full h-full flex flex-col">
            {
                (sortingItems || products) &&
                <div>
                    <div className="font-bold text-[25px] leading-[33px] mt-[10px] mb-[20px]">
                        Acquisti e metodi di pagamento
                    </div>
                    <FolderBox
                        folder={Folder.PaymentMethod}
                        onClick={onManageSubscriptionsClick}
                    />
                    <div className="font-bold text-[12px] leading-[20px] my-[20px]">
                        I tuoi acquisti
                    </div>
                    {
                        sortingItems && sortingItems.map((sortingItem, index) => (
                            <SortingItemCard
                                key={index}
                                item={sortingItem}
                                triggerRefresh={triggerRefresh}
                            />
                        ))
                    }
                    {
                        products && products.map((product, index) => (
                            <ProductCard
                                key={index}
                                item={product}
                            />
                        ))
                    }
                    <div className="w-full h-[50px]">
                        <PrimaryButton
                            text="Ho un codice riscatto"
                            pointer={true}
                            disabled={false}
                            onClick={() => navigate("redeem")}
                        />
                    </div>
                    <div className="p-[10px]" />
                </div>
            }
            {
                isLoading &&
                <div className="w-full h-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col justify-center items-center">
                    <LoadingSpinner />
                </div>
            }
        </div>
    )
}

export default PaymentMethodsAndPurchases


interface SortingItemCardProps {
    item: SortingItem,
    triggerRefresh: () => void;
}

const SortingItemCard: React.FC<SortingItemCardProps> = ({
    item,
    triggerRefresh,
}) => {
    const { auth } = useAuth()
    const userId = auth.userId
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const { productsData } = useProducts()
    const dnaTest = productsData.dnaTest
    const membership = productsData.membership
    const { experts } = useExpert()
    const nutritionist = experts.nutritionist

    const isRedeemable = item.redeemability === true

    const linkedToMe = email && item.redeemed_by === email || false
    // const linkedToSomeOne = item.redeemed_by !== null

    const [isToggled, setIsToggled] = useState(false)
    const [isRedeemPopupOpen, setIsRedeemPopupOpen] = useState(false)
    const [isWarningOpen, setIsWarningOpen] = useState(false)
    const [isAssignPopupOpen, setIsAssignPopupOpen] = useState(false)

    const [redeemableBy, setRedeemableBy] = useState(item.redeemable_by ? item.redeemable_by : "") // New state
    const [assignButtonEnabled, setAssignButtonEnabled] = useState(false)

    const [isLoading, setIsLoading] = useState(false)

    const isDnaProduct = item.product_type === ProductType.DnaTest ||
        item.product_type === ProductType.SubHealthDna ||
        item.product_type === ProductType.SubNutritionDna

    const isSubProduct = item.product_type === ProductType.SubHealth ||
        item.product_type === ProductType.SubNutrition ||
        item.product_type === ProductType.SubHealthDna ||
        item.product_type === ProductType.SubNutritionDna ||
        item.product_type === ProductType.SubHealthUpsell

    const isSubWithoutDnaProduct = item.product_type === ProductType.SubHealth || item.product_type === ProductType.SubNutrition

    const shouldShowWarningPopup = dnaTest && (isDnaProduct) ||
        membership && (isSubProduct) ||
        (!dnaTest && (isSubWithoutDnaProduct))

    const handleToggle = () => {
        if (shouldShowWarningPopup) {
            setIsWarningOpen(true)
        } else {
            setIsRedeemPopupOpen(!isRedeemPopupOpen)
        }
    }

    const closePopup = () => {
        setIsRedeemPopupOpen(false)
    }

    const closeWarning = () => {
        setIsWarningOpen(false)
    }

    const handleRedeemableByChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (!assignButtonEnabled) {
            setAssignButtonEnabled(true)
        }
        setRedeemableBy(e.target.value)
    }

    const onAssignConfirmClick = (newRedeemer: string) => {
        if (!idToken) return
        setIsLoading(true)
        assignSortingItems(
            item.id,
            newRedeemer,
            idToken,
            (success) => {
                console.log("success");
                setIsAssignPopupOpen(false) // Resolve the promise on success
                setIsLoading(false)
                triggerRefresh()
            },
            (error) => {
                console.log(error);
                setIsLoading(false)
            }
        );
    }

    const onAssociaConfirmClick = () => {
        if (!idToken || !userId || !email) return
        setIsLoading(true)
        redeemSortingItems(
            userId,
            item.id,
            email,
            idToken,
            () => {
                createProduct(
                    userId,
                    idToken,
                    item.product_type,
                    (success) => {
                        // Handle the successful product creation
                        if ((item.product_type === ProductType.SubHealth || item.product_type === ProductType.SubNutrition) && !nutritionist) {
                            assignNutri(); // Call to assign the nutritionist
                        }
                        setIsLoading(false)
                        triggerRefresh()
                    },
                    (error) => {
                        console.log(error);

                    },
                    {
                        shopify_product: item.product_type,
                        channel: CreateProductChannel.ShopifyPurchase,
                        code: null,
                        buyer: item.buyer,
                        redeemer: email,
                    }
                );
            },
            (error) => { console.log(error) }
        )
    }

    const assignNutri = () => {
        callGetMedicalUsersList()
    }

    const callGetMedicalUsersList = async () => {
        if (!idToken || !userId) return
        try {
            const nutritionists = await getMedicalUserByRole(idToken, ExpertRole.Nutritionist)
            const nutritionist = getRandomNutritionist(nutritionists)
            const newExpert = createExpertFromCalendlyExpert(nutritionist)
            assignNutritionist(newExpert.id!)
        } catch (error) {
            console.log(error)
        }
    }

    const assignNutritionist = (nutriId: string) => {
        if (!userId || !idToken) return
        assignMedicalUserToPatient(
            nutriId,
            userId,
            idToken,
            () => { triggerRefresh() },
            (error) => {
                console.log(error)
                triggerRefresh()
            },
        )
    }

    const onConfirmRedeemClick = () => {
        if (!userId || !email || !idToken) return
        setIsLoading(true)

        redeemSortingItems(
            userId,
            item.id,
            email,
            idToken,
            (success) => {
                const buyer = success.buyer;
                const type = success.product_type;
                const redeemer = email
                const shipping_address = success.product_data.shipping_address

                createProduct(
                    userId,
                    idToken,
                    type,
                    (success) => {
                        // Handle the successful product creation
                        if ((type === ProductType.Nutritionist || type === ProductType.SubHealth || type === ProductType.SubNutrition || type === ProductType.SubHealthUpsell) && !nutritionist) {
                            assignNutri(); // Call to assign the nutritionist
                        }
                        setIsLoading(false)
                        triggerRefresh()
                        closePopup()
                    },
                    (error) => {
                        setIsLoading(false)
                        console.log(error);
                    },
                    {
                        shopify_product: type,
                        channel: CreateProductChannel.ShopifyPurchase,
                        code: null,
                        buyer: buyer,
                        redeemer: redeemer,
                        shipping_address: shipping_address,
                    }
                );
            },
            () => { },
        )
    }

    const handleAssignConfirm = (newRedeemableBy: string) => {
        // Use the updated value from the popup
        setRedeemableBy(newRedeemableBy);
        onAssignConfirmClick(newRedeemableBy); // Call your confirm logic with the updated value
    };

    // ********************************************************************************

    return (
        <div className="w-full p-[20px] rounded-[15px] shadow-platform mb-[20px]">
            <div className="w-full flex items-center justify-between">
                <div className="flex flex-col font-bold text-[14px] leading-[26px]">
                    <div>{handleShopifyProductName(item.product_type)}</div>
                    {isSubProduct && <div className="font-normal text-[14px] leading-[17px]">Abbonamento semestrale</div>}
                </div>
                {
                    (!isRedeemable) ?
                        <div className="flex flex-col items-end">
                            <div className="font-semibold text-[10px] leading-[26px]">{linkedToMe ? "Assegnato a me" : `Riscattato `}</div>
                            {isSubProduct && !isRedeemable && <div className="font-bold text-[10px] leading-[14px] text-holifya-blue">Attivo</div>}
                        </div> :
                        <div className="flex items-center gap-2">
                            <div className={`font-semibold text-[10px] leading-[26px] ${(!isToggled) ? "text-holifya-blue" : ""}`}>
                                {(isToggled) ? "Assegnato a me" : "Assegna a me"}
                            </div>
                            <div
                                className={`w-[40px] h-[20px] flex items-center rounded-[25px] cursor-pointer ${(isToggled) ? "bg-[#D6E3F7]" : "bg-[#D9D9D9]"}`}
                                onClick={handleToggle}
                            >
                                <div className={`w-[20px] h-[20px] rounded-[10px] transform duration-300 ease-in-out ${(isToggled) ? "translate-x-[20px] bg-[#3B82F7]" : "bg-[#B8B8B8]"}`} />
                            </div>
                        </div>
                }
            </div>
            {
                (isRedeemable) ?
                    <div className="mt-[20px]">
                        <div className="w-full">
                            <div className="font-normal text-[14px] leading-[20px] mb-[5px]">Non ancora riscattato da</div>
                            <div onClick={() => setIsAssignPopupOpen(true)} className={`w-full h-[40px] rounded-[5px] border border-solid border-[#cecece] font-normal pl-[15px] text-[14px] leading-[20px] flex items-center cursor-pointer ${item.redeemable_by === null ? "text-[#cecece]" : "text-black"}`}>
                                {item.redeemable_by || "Inserisci e-mail"}
                            </div>
                        </div>
                    </div> : (item.redeemability && isSubProduct) && <div className="w-full h-[50px] mt-[20px]"><PrimaryButton text={"Associa"} pointer={true} disabled={false} onClick={onAssociaConfirmClick} isLoading={isLoading} /></div>
            }
            {
                isRedeemPopupOpen &&
                <RedeemPopup
                    productName={handleShopifyProductName(item.product_type)}
                    onConfirmClick={onConfirmRedeemClick}
                    isLoading={isLoading}
                    onClose={closePopup}
                />
            }
            {
                isWarningOpen &&
                <WarningDialog
                    text="Non puoi associare questo acquisto al tuo account. Inserisci una mail a cui regalare questo prodotto."
                    onClose={closeWarning}
                />
            }
            {
                isAssignPopupOpen &&
                <AssignPopup
                    item={item}
                    initialRedeemableBy={redeemableBy}
                    onAssignConfirmClick={handleAssignConfirm}
                    onClose={() => setIsAssignPopupOpen(false)}
                    isLoading={isLoading}
                />
            }
        </div>
    )
}

interface ProductCardProps {
    item: UserProduct,
}

const ProductCard: React.FC<ProductCardProps> = ({
    item,
}) => {
    // ********************************************************************************

    return (
        <div className="w-full p-[20px] rounded-[15px] shadow-platform mb-[20px]">
            <div className="w-full flex items-center justify-between">
                <div className="flex flex-col font-bold text-[14px] leading-[26px]">
                    <div>{handleShopifyProductName(item.product_type)}</div>
                </div>
                <div className="flex flex-col items-end">
                    <div className="font-semibold text-[10px] leading-[26px]">{item.data.channel === CreateProductChannel.RedeemCode ? item.data.code : "Importato"}</div>
                </div>
            </div>
        </div>
    )
}
