/**
 * Props interface for TextInputElement.
 * 
 * @property {string} name - The name for the input field.
 * @property {string} label - The label text for the input field.
 * @property {string} placeholder - The placeholder text for the input field.
 * @property {string} errorText - The error message to display.
 * @property {string} inputValue - The current value of the input field.
 * @property {(e: React.ChangeEvent<HTMLInputElement>) => void} onChange - Callback function to handle input changes.
 */
interface Props {
    name?: string,
    label: string,
    placeholder: string,
    errorText: string,
    inputValue: string,
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void,
}

/**
 * TextInputElement Component
 * 
 * A reusable text input component with label, placeholder, error message, and change handler.
 * 
 * @param {Props} props - The props for the component.
 * @returns {JSX.Element} The rendered text input element.
 */
const TextInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    errorText,
    inputValue,
    onChange,
}) => {
    return (
        <div className="flex flex-col w-full space-y-[10px]">
            <div className="flex flex-col">
                <label
                    className="font-semibold text-[12px] uppercase"
                    htmlFor={`text-input-${label}`}
                >
                    {label}
                </label>
                <input
                    name={name}
                    id={`text-input-${label}`}
                    className="outline-none h-[40px] border-b border-solid border-black rounded-[0px] pl-[5px] text-[16px] font-normal"
                    placeholder={placeholder}
                    value={inputValue}
                    type="text"
                    onChange={onChange}
                />
            </div>
            {errorText && <div className="mt-[10px] text-[12px] font-semibold leading-[17px] text-holifya-red" aria-live="polite">
                {errorText}
            </div>}
        </div>
    )
}

export default TextInputElement
