import countries from 'i18n-iso-countries';
import it from 'i18n-iso-countries/langs/it.json';
import { CountryCode, getCountryCallingCode } from "libphonenumber-js";
import { useEffect, useRef, useState } from "react";
import Flag from "react-world-flags";
import { topCountryCodes } from '../../../utils/Constants';
import { Country } from "../../../utils/objects";

interface Props {
    name: string;
    label: string;
    placeholder: string;
    errorText: string;
    inputValue: string;
    onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const PhoneInputElement: React.FC<Props> = ({
    name,
    label,
    placeholder,
    errorText,
    inputValue,
    onInputChange,
}) => {
    countries.registerLocale(it);

    const validCountryCodes = Object.keys(countries.getAlpha2Codes()) as CountryCode[];

    const countryList = Object.entries(countries.getNames("it"))
        .filter(([code]) => validCountryCodes.includes(code as CountryCode))
        .map(([code, name]) => {
            try {
                const prefix = `+${getCountryCallingCode(code as CountryCode)}`;
                return { code, name, prefix };
            } catch {
                return null;
            }
        })
        .filter((country): country is { code: string; name: string; prefix: string } => Boolean(country));

    const sortedCountryList = [
        ...topCountryCodes
            .map((topCode) => countryList.find((country) => country.code === topCode))
            .filter((country): country is Country => Boolean(country)),
        ...countryList.filter((country) => !topCountryCodes.includes(country.code as CountryCode))
            .sort((a, b) => a.name.localeCompare(b.name)),
    ];

    const [selectedCountry, setSelectedCountry] = useState<Country>(
        countryList.find((c) => c.prefix === `+${inputValue.slice(0, 3)}`) || sortedCountryList[0]
    );

    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState(inputValue.replace(/^\+\d+\s*/, ''));

    const inputRef = useRef<HTMLInputElement>(null);

    const handleCountrySelect = (country: Country) => {
        setSelectedCountry(country);
        setPhoneNumber('')
        onInputChange({ target: { name, value: country.prefix + ' ' + phoneNumber } } as React.ChangeEvent<HTMLInputElement>);
        setIsDropdownOpen(false);
        inputRef.current?.focus();
    };

    const toggleDropdown = () => {
        setIsDropdownOpen((prev) => !prev);
    };

    const dropdownRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const handleOutsideClick = (event: MouseEvent) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    // ********************************************************************************

    return (
        <div className="flex flex-col w-full space-y-[10px]">
            <label className="font-semibold text-[12px] uppercase">{label}</label>
            <div className="flex border-b border-solid border-black relative">
                <div className="h-[40px] relative" ref={dropdownRef}>
                    <button
                        className="w-[50px] h-[90%] flex items-center justify-center border outline-none"
                        type="button"
                        onClick={toggleDropdown}
                        aria-expanded={isDropdownOpen ? "true" : "false"}
                        aria-controls="country-dropdown"
                    >
                        <div className="w-[50%] h-[50%] flex items-center">
                            <Flag
                                code={selectedCountry.code}
                                style={{ width: "100%", height: "100%" }}
                            />
                        </div>
                    </button>
                    {isDropdownOpen && (
                        <div
                            id="country-dropdown"
                            className="absolute left-0 mt-2 px-[20px] bg-white border rounded shadow-md z-10 min-w-[200px] max-h-60 overflow-y-auto scrollbar-hide"
                        >
                            {sortedCountryList.map((country) => (
                                <div
                                    key={country.code}
                                    onClick={() => handleCountrySelect(country)}
                                    className="flex items-center space-x-[10px] px-[5px] py-2 hover:bg-gray-100 cursor-pointer"
                                >
                                    <Flag
                                        code={country.code}
                                        style={{ width: "20px", height: "15px" }}
                                    />
                                    <div className="">{country.name}</div>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
                <input
                    ref={inputRef}
                    name={name}
                    id={`phone-input-${name}`}
                    className="w-full h-[40px] pl-2 outline-none"
                    placeholder={placeholder}
                    value={`${selectedCountry.prefix} ${inputValue.replace(/^\+\d+\s*/, "")}`}
                    type="tel"
                    onChange={onInputChange}
                />
            </div>
            {errorText && (
                <div className="text-[12px] font-semibold leading-[17px] text-holifya-red">{errorText}</div>
            )}
        </div>
    );
};

export default PhoneInputElement;
