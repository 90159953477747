import { useEffect, useRef, useState } from "react"
import { useNavigate } from "react-router-dom"
import PrimaryButton from "../../../components/buttons/PrimaryButton"
import { saveEvent } from "../../../utils/apis/user-service"
import { useAuth } from "../../../utils/context/AuthContext"
import { useUserData } from "../../../utils/context/UserDataContext"
import { EventType } from "../../../utils/enums/EventType"
import { TrackingStatus } from "../../../utils/enums/TrackingStatus"

interface Props {
    status: TrackingStatus,
}

const TrackingWidget: React.FC<Props> = ({
    status,
}) => {
    const { auth } = useAuth()
    const idToken = auth.idToken
    const { userData } = useUserData()
    const email = userData.email
    const navigate = useNavigate()
    const handleOnClick = (status: TrackingStatus) => {
        switch (status) {
            case TrackingStatus.Delivered:
                return navigate("tutorial")
            case TrackingStatus.TestDone:
                return navigate("register-swab-code")
            case TrackingStatus.SampleRegistered:
                return navigate("packing")
            case TrackingStatus.PickupRequested:
                return navigate("request-pickup")
            default:
                return
        }
    }

    const onTrackerButtonClick = () => {
        handleOnClick(status)
        if (!email || !idToken) return
        saveEvent(
            email,
            EventType.DnaTrackerButtonClicked,
            (success) => { console.log(success) },
            (error) => { console.log(error) },
            "dna_test_page",
        )
    }

    const handleFullHeight = () => {
        switch (status) {
            case TrackingStatus.Purchased:
            case TrackingStatus.Departed:
            case TrackingStatus.DeliveredToLaboratory:
            case TrackingStatus.StartingToExtract:
            case TrackingStatus.StartingAnalysis:
                return "h-[168px]"
            case TrackingStatus.Delivered:
            case TrackingStatus.TestDone:
            case TrackingStatus.SampleRegistered:
            case TrackingStatus.PickupRequested:
                return "h-[214px]"
            default:
                return ""
        }
    }
    const isMdScreen = useIsMdScreen();

    // ********************************************************************************

    return (
        <div className={`${handleFullHeight()} w-[calc(100%-4px)] rounded-[15px] self-center flex items-center justify-end shadow-platform flex-shrink-0`}>
            <div className="w-full h-[85%] flex flex-col justify-between">
                <div className="font-bold text-[12px] leading-[17px] ml-5p md:ml-[20px]">Il tuo campione genetico</div>
                <BullettedLine percentage={handlePercentage(status, isMdScreen)} status={status} />
                {
                    (status === TrackingStatus.Purchased || status === TrackingStatus.Departed || status === TrackingStatus.DeliveredToLaboratory || status === TrackingStatus.StartingToExtract || status === TrackingStatus.StartingAnalysis) &&
                    <div className="font-normal text-[16px] leading-[24px] ml-5p md:ml-[20px]">{handleSmallBoxText(status)}</div>}
                {
                    (status === TrackingStatus.Delivered || status === TrackingStatus.TestDone || status === TrackingStatus.SampleRegistered || status === TrackingStatus.PickupRequested) &&
                    <div className="w-[90%] h-[50px] self-center md:w-[330px] md:self-start md:ml-[20px]">
                        <PrimaryButton
                            text={handleButtonTest(status)}
                            pointer={true}
                            disabled={false}
                            onClick={onTrackerButtonClick}
                        />
                    </div>
                }
            </div>
        </div>
    )
}

const handleSmallBoxText = (status: TrackingStatus) => {
    switch (status) {
        case TrackingStatus.Purchased:
            return "Il tuo test è in arrivo!"
        case TrackingStatus.Departed:
            return "Il tuo campione è in viaggio verso il laboratorio."
        case TrackingStatus.DeliveredToLaboratory:
            return "Il tuo campione è arrivato in laboratorio."
        case TrackingStatus.StartingToExtract:
            return "È iniziata l'estrazione del tuo campione."
        case TrackingStatus.StartingAnalysis:
            return "È iniziata l'analisi del tuo campione."
    }
}

const handleButtonTest = (status: TrackingStatus) => {
    switch (status) {
        case TrackingStatus.Delivered:
            return "Effettua il test e prosegui"
        case TrackingStatus.TestDone:
            return "Registra codice campione"
        case TrackingStatus.SampleRegistered:
            return "Richiedi il ritiro del campione"
        case TrackingStatus.PickupRequested:
            return "Modifica il ritiro del campione"
        default:
            return ""
    }
}

const useIsMdScreen = () => {
    const [isMdScreen, setIsMdScreen] = useState(window.innerWidth >= 768);

    useEffect(() => {
        const handleResize = () => {
            setIsMdScreen(window.innerWidth >= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return isMdScreen;
};

const handlePercentage = (status: TrackingStatus, isMdScreen: boolean) => {

    switch (status) {
        case TrackingStatus.Purchased:
            return 15
        case TrackingStatus.Delivered:
            return 22
        case TrackingStatus.TestDone:
            return isMdScreen ? 37 : 36
        case TrackingStatus.SampleRegistered:
            return isMdScreen ? 52 : 51
        case TrackingStatus.PickupRequested:
            return isMdScreen ? 52 : 51
        case TrackingStatus.Departed:
            return 57
        case TrackingStatus.DeliveredToLaboratory:
            return 70
        case TrackingStatus.StartingToExtract:
            return 83
        case TrackingStatus.StartingAnalysis:
            return 94
        default:
            return 0
    }
}

export default TrackingWidget

interface BulletProps {
    percentage: number,
    status: TrackingStatus,
}

const BullettedLine: React.FC<BulletProps> = ({
    percentage,
    status,
}) => {
    const containerRef = useRef<HTMLDivElement | null>(null);

    // Track the calculated fillWidth
    const [fillWidth, setFillWidth] = useState("");

    const calculateFillWidth = () => {
        const isMd = window.matchMedia("(min-width: 768px)").matches;
        return `calc(${percentage}% - ${isMd ? 110 : 150}px)`;
    };

    // Update fillWidth on `percentage` or screen resize
    useEffect(() => {
        setFillWidth(calculateFillWidth());

        const handleResize = () => setFillWidth(calculateFillWidth());
        window.addEventListener("resize", handleResize);

        return () => window.removeEventListener("resize", handleResize);
    }, [percentage]);

    useEffect(() => {
        if (containerRef.current) {
            const trackerItems = containerRef.current.querySelectorAll('.tracker-item');

            let statusIndex = trackingStates.indexOf(status);
            if (statusIndex === 4) {
                statusIndex = 3
            } else if (statusIndex > 4) {
                statusIndex = statusIndex - 2
            }

            if (trackerItems[statusIndex]) {
                const targetItem = trackerItems[statusIndex] as HTMLElement;
                targetItem.scrollIntoView({
                    behavior: 'smooth',
                    block: 'nearest',
                    inline: 'center',
                });
            }
        }
    }, [status]);

    // ********************************************************************************

    return (
        <div className="w-full h-[55px] overflow-x-auto flex items-center relative scrollbar-hide">
            <div className="w-[1500px] h-[80%] flex justify-between relative flex-shrink-0 pl-[50px] pr-[50px] tracker-line md:w-full md:pl-[30px] md:pr-[30px]" ref={containerRef}>
                <div className="absolute top-[8px] left-[75px] right-[75px] h-[2px] bg-holifya-blue tracker-line-filled md:left-[55px] md:right-[55px]" style={{ width: fillWidth }}></div>
                <TrackerItem text="Acquisto" isTextBlue={false} dotType={DotType.Filled} />
                <TrackerItem text="Effettua il test" isTextBlue={status === TrackingStatus.Delivered} dotType={status === TrackingStatus.Purchased ? DotType.Normal : (status === TrackingStatus.Delivered ? DotType.BlueBorder : DotType.Filled)} />
                <TrackerItem text="Registra campione" isTextBlue={status === TrackingStatus.TestDone} dotType={(status === TrackingStatus.Purchased || status === TrackingStatus.Delivered) ? DotType.Normal : (status === TrackingStatus.TestDone ? DotType.BlueBorder : DotType.Filled)} />
                <TrackerItem text="Richiedi il ritiro" isTextBlue={status === TrackingStatus.SampleRegistered} dotType={(status === TrackingStatus.Purchased || status === TrackingStatus.Delivered || status === TrackingStatus.TestDone) ? DotType.Normal : (status === TrackingStatus.SampleRegistered ? DotType.BlueBorder : DotType.Filled)} />
                <TrackerItem text="In laboratiorio" isTextBlue={false} dotType={trackingStates.indexOf(status) >= trackingStates.indexOf(TrackingStatus.DeliveredToLaboratory) ? DotType.Filled : DotType.Normal} />
                <TrackerItem text="Inizio estrazione" isTextBlue={false} dotType={trackingStates.indexOf(status) >= trackingStates.indexOf(TrackingStatus.StartingToExtract) ? DotType.Filled : DotType.Normal} />
                <TrackerItem text="Analisi" isTextBlue={false} dotType={trackingStates.indexOf(status) >= trackingStates.indexOf(TrackingStatus.StartingAnalysis) ? DotType.Filled : DotType.Normal} />
                <TrackerItem text="Risultato" isTextBlue={false} dotType={DotType.Normal} />
            </div>
        </div>
    )
}

interface ItemProps {
    text: string,
    isTextBlue: boolean,
    dotType: DotType,
}

const TrackerItem: React.FC<ItemProps> = ({
    text,
    isTextBlue,
    dotType,
}) => {
    return (
        <div className="flex flex-col items-center justify-between tracker-item">
            <div className={`w-[16px] h-[16px] rounded-[8px] z-[10] ${handleDotType(dotType)}`} />
            <div className={`font-bold text-[14px] leading-[20px] ${isTextBlue && "text-holifya-blue"}`}>{text}</div>
        </div>
    )
}

const handleDotType = (dotType: DotType) => {
    switch (dotType) {
        case DotType.Filled:
            return "bg-holifya-blue border-2 border-solid border-holifya-blue"
        case DotType.BlueBorder:
            return "bg-white border-2 border-solid border-holifya-blue"
        case DotType.Normal:
            return "bg-white border-2 border-solid border-holifya-light-blue"
    }
}

enum DotType {
    Filled = "filled",
    BlueBorder = "blue-border",
    Normal = "normal",
}

const trackingStates = [
    TrackingStatus.Purchased,
    TrackingStatus.Delivered,
    TrackingStatus.TestDone,
    TrackingStatus.SampleRegistered,
    TrackingStatus.PickupRequested,
    TrackingStatus.Departed,
    TrackingStatus.DeliveredToLaboratory,
    TrackingStatus.StartingToExtract,
    TrackingStatus.StartingAnalysis,
    TrackingStatus.AnalysisDone,
    TrackingStatus.ReportGenerated,
]
