import React, { useState } from "react";
import PrimaryButton from "../../../../components/buttons/PrimaryButton";
import WhiteButton from "../../../../components/buttons/WhiteButton";
import TextInputElement from "../../../../components/input/v1/TextInputElement";

interface Props {
    onSubmit: (inputData: { [key: string]: string }) => void;
    onResendCodeClick: () => void,
}

const ForgotPasswordInsertCodeBox: React.FC<Props> = ({
    onSubmit,
    onResendCodeClick,
}) => {

    const [inputs, setInputs] = useState<{ [key: string]: string }>({
        code: '',
    });

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;

        setInputs((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        onSubmit(inputs);
    }

    // ********************************************************************************
    return (
        <div className="w-full flex flex-col items-center">
            <div className="font-bold text-[30px] leading-[40px] my-[20px]">
                Inserisci codice
            </div>
            <div className="font-normal text-[18px] leading-[26px] text-center my-[20px]">
                Hai ricevuto per e-mail un codice a 6 cifre per verificare il tuo indirizzo e-mail. Inseriscilo qui per reimpostare una nuova password.
            </div>
            <form
                className="w-full space-y-[20px]"
                onSubmit={handleSubmit}
            >
                <TextInputElement
                    name="code"
                    label="Codice recupero password"
                    placeholder="000000"
                    errorText={""}
                    inputValue={inputs.code}
                    onChange={handleInputChange}
                />
                <div className="w-full h-[55px] mb-[20px]">
                    <PrimaryButton
                        text="Conferma codice"
                        pointer={inputs.code.length === 6}
                        disabled={!(inputs.code.length === 6)}
                    />
                </div>
            </form>
            <div className="w-full h-[55px]">
                <WhiteButton
                    text="Invia nuovo codice"
                    onClick={onResendCodeClick}
                />
            </div>
        </div>
    )
}

export default ForgotPasswordInsertCodeBox
