import { Folder } from "./Folder"

interface Props {
  folder: Folder,
  onClick: () => void,
}

const FolderBox: React.FC<Props> = ({
  folder,
  onClick,
}) => {
  return (
    <div className="w-full h-[111px] rounded-[5px] flex items-center justify-center self-center flex-shrink-0 shadow-platform cursor-pointer p-[20px]" onClick={onClick}>
        <div className="w-full">
        <div className="font-bold text-[20px] leading-[33px]">{handleFolderName(folder)}</div>
        </div>
    </div>
  )
}

export default FolderBox

const handleFolderName = (folder: Folder) => {
  switch (folder) {
    case Folder.Results:
      return `${"\u{1F4CA}"} Risultati e progressi`
    case Folder.TestsDocs:
      return `${"\u{1F4C1}"} Test e referti`
    case Folder.DNATest:
      return `${"\u{1F9EC}"} DNA Test`
    case Folder.BloodExams:
      return `${"\u{1FA78}"} Analisi degli Aminoacidi`
    case Folder.HealthAssessment:
      return `${"\u{1F4DD}"} Health Assessment`
    case Folder.UploadedForYou:
      return `${"\u{1F5C2}"} Caricati per te`
    case Folder.UploadedFromYou:
      return `${"\u{1F5C2}"} Caricati da te`
    case Folder.PaymentMethod:
      return `${"\u{1F4B3}"} Gestione abbonamento`
    case Folder.Appointments:
      return `🗓️ I tuoi appuntamenti`
    case Folder.Experts:
      return `👩‍⚕️👨‍⚕️ I tuoi Professionisti`
    case Folder.MealPlan:
      return "🍲 Meal Plan"
  }
}
